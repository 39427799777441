import { Formik } from "formik"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"
import SpinnerAdornment from "./SpinnerAdornment"

export type SignUpValues =
  | Record<string, any>
  | {
      firstName: string
      lastName: string
      email: string
      password: string
    }
interface SignUpFormProps {
  classes: any
  onSubmit: (signUpData: SignUpValues) => void
  isLoading: boolean
}
const SignUpForm = ({ classes, isLoading, onSubmit }: SignUpFormProps) => {
  const { t } = useTranslation()
  return (
    <Formik
      initialValues={{ firstName: "", lastName: "", email: "", password: "" }}
      validate={(values) => {
        const errors: SignUpValues = {}
        if (!values.firstName) {
          errors.firstName = t("screens.SignUp.errors.firstName")
        }
        if (!values.lastName) {
          errors.lastName = t("screens.SignUp.errors.lastName")
        }
        if (!values.email) {
          errors.email = t("screens.SignUp.errors.email")
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = "Invalid email address"
        }
        if (!values.password) {
          errors.password = t("screens.SignUp.errors.password")
        } else if (values.password.length < 4) {
          errors.password = t("screens.SignUp.errors.passwordLength")
        }
        return errors
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        dirty
      }) => (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstName"
            label={t("screens.SignUp.labels.firstName")}
            name="firstName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.firstName}
            autoFocus
          />
          {errors.firstName && touched.firstName && errors.firstName}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label={t("screens.SignUp.labels.lastName")}
            name="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.lastName}
          />
          {errors.lastName && touched.lastName && errors.lastName}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("screens.SignUp.labels.email")}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            autoComplete="off"
          />
          {errors.email && touched.email && errors.email}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("screens.SignUp.labels.password")}
            type="password"
            id="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            autoComplete="off"
          />
          {errors.password && touched.password && errors.password}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={(isSubmitting && isLoading) || !dirty}
          >
            {isSubmitting && isLoading ? <SpinnerAdornment /> : t("screens.SignUp.buttonSubmit")}
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default SignUpForm
