import {
  FETCH_COIN_VALUE_SUCCESS,
  FETCH_NOTIFICATIONS_FAILED,
  FETCH_NOTIFICATIONS_START,
  FETCH_NOTIFICATIONS_SUCCESS,
  REQUEST_BUY_ASSET_FAILED,
  REQUEST_BUY_ASSET_START,
  REQUEST_BUY_ASSET_SUCCESS
} from "../actions/cart"

const initialState = {
  loadingBuy: false,
  errorBuy: false,
  successRequestBuy: false,
  notifications: [],
  isLoadingNotifications: false,
  errorNotifications: false,
  coinValue: 0
}

const cartReducer = function (state = initialState, action) {
  switch (action.type) {
    case REQUEST_BUY_ASSET_START:
      return {
        ...state,
        loadingBuy: true
      }
    case REQUEST_BUY_ASSET_SUCCESS:
      return {
        ...state,
        loadingBuy: false,
        successRequestBuy: true,
        errorBuy: false
      }
    case REQUEST_BUY_ASSET_FAILED:
      return {
        ...state,
        loadingBuy: false,
        successRequestBuy: false,
        errorBuy: true
      }
    case FETCH_NOTIFICATIONS_START:
      return {
        ...state,
        isLoadingNotifications: true
      }
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        isLoadingNotifications: false,
        notifications: action.payload,
        errorNotifications: false
      }
    case FETCH_NOTIFICATIONS_FAILED:
      return {
        ...state,
        isLoadingNotifications: false,
        errorNotifications: true
      }
    case FETCH_COIN_VALUE_SUCCESS:
      return {
        ...state,
        coinValue: action.payload
      }
    default:
      return state
  }
}

export default cartReducer
