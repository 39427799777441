import React from "react"
import clsx from "clsx"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import AccountBox from "@material-ui/icons/AccountBox"
import { Badge, ClickAwayListener, Grow, MenuItem, MenuList, Popper } from "@material-ui/core"
import Paper from "@material-ui/core/Paper"
import { useDispatch, useSelector } from "react-redux"
import { logout } from "../../store/actions/auth"
import { useHistory, Link } from "react-router-dom"
import { default as MaterialAppBar } from "@material-ui/core/AppBar"
import InputIcon from "@material-ui/icons/Input"
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet"
import AddBoxOutlined from "@material-ui/icons/AddBoxOutlined"
import NotificationsIcon from "@material-ui/icons/NotificationsActive"
import useAppBarStyles from "./styles"
import { GlobalState } from "../../store/reducers"

const AppBar = (props) => {
  const classes: any = useAppBarStyles()
  const history: any = useHistory()
  const dispatch = useDispatch()
  const { user } = useSelector((state: GlobalState) => state.authReducer)
  const { notifications } = useSelector((state: GlobalState) => state.cartReducer)
  const onLogOut = (callback) => dispatch(logout(callback))
  const notificationsCount = notifications?.length || 0
  const [open, setOpen] = React.useState(false)
  const handleLogout: any = () => {
    onLogOut(() => null)
  }
  const goToLogin = () => history.push("/login")
  const goToWallet = () => history.push("/wallet")
  const goToNewAsset = () => history.push("/newAsset")
  const goToNotifications = () => history.push("/notifications")

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = (event: React.MouseEvent<EventTarget>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }
  const anchorRef = React.useRef<HTMLButtonElement>(null)

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus()
    }

    prevOpen.current = open
  }, [open])

  return (
    <MaterialAppBar position="absolute" className={clsx(classes.appBar)}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <Link to="/" className={classes.titleProject}>
            <img
              src="https://nftstudios.online/wp-content/uploads/2021/06/nft-studios-logo.jpg"
              alt=""
              className={classes.logo}
            />
          </Link>
        </div>
        {user ? (
          <>
            <div className={classes.accountRow}>
              <Typography
                component="h3"
                variant="h6"
                color="inherit"
                noWrap
                className={classes.title}
              >
                {user?.email}
              </Typography>
              <IconButton ref={anchorRef} color="inherit" onClick={handleToggle}>
                <AccountBox />
              </IconButton>
            </div>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === "bottom" ? "center top" : "center bottom"
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        {/*<MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>*/}
                        <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <IconButton color="inherit" onClick={goToNewAsset}>
              <AddBoxOutlined />
            </IconButton>
            <IconButton color="inherit" onClick={goToWallet}>
              <AccountBalanceWalletIcon />
            </IconButton>
            <IconButton color="inherit" onClick={goToNotifications}>
              {notificationsCount > 0 ? (
                <Badge badgeContent={notificationsCount} color="secondary">
                  <NotificationsIcon />
                </Badge>
              ) : (
                <NotificationsIcon />
              )}
            </IconButton>
          </>
        ) : (
          <IconButton color="inherit" onClick={goToLogin}>
            <InputIcon />
          </IconButton>
        )}
      </Toolbar>
    </MaterialAppBar>
  )
}

export default AppBar
