import bigInt from "./bigInt"
import sb from "satoshi-bitcoin"
import { ASPIRE_COIN_ASSETS } from "./constants"

export const getBalanceAssetData = (balances, assets) => {
  if (!balances) {
    return []
  }
  return balances.map((balance) => {
    const asset: any = assets.filter((asset) => asset.asset === balance.asset)
    balance.data = asset[0]?.details || ""
    return balance
  })
}

export const generateAssetName = () => {
  const NUMERIC_ASSET_ID_MIN = bigInt(26).pow(12).add(1)
  const NUMERIC_ASSET_ID_MAX = bigInt(256).pow(8)
  const r = bigInt.randBetween(NUMERIC_ASSET_ID_MIN, NUMERIC_ASSET_ID_MAX)
  return `ASP${r}`
}

export const getAssetsWithOwnerData = (addressesFiltered, assetsInfoArray) => {
  const addressesFilteredIds = addressesFiltered.map((address) => address.addressId)
  const finalAssets = []
  assetsInfoArray.map((asset) => {
    const addressIndex = addressesFilteredIds.indexOf(asset.owner)
    if (addressIndex >= 0) {
      asset.ownerData = addressesFiltered[addressIndex]
      finalAssets.push(asset)
    }
  })
  return finalAssets
}

export const getGaspBalance = (chainBalance) => {
  let gaspBalance = 0
  if (chainBalance && chainBalance.length > 0) {
    gaspBalance = chainBalance[0]["info"]["balance"].toFixed(4)
  }
  return gaspBalance
}

export const mergeBalancesWithAssetInfo = (aspireAssets, assetsArray, mySalableAssets) => {
  return aspireAssets.map((asset) => {
    let newAssetObj = asset
    const assetObjectArray = assetsArray.filter((nft) => nft.asset === asset.asset)
    const isSalableFilter = mySalableAssets.filter(
      (salableAsset) => salableAsset.assetName === asset.asset
    )
    const isSalable = isSalableFilter && isSalableFilter.length > 0
    const salableAssetId =
      isSalableFilter && isSalableFilter.length > 0 ? isSalableFilter[0].id : null
    if (assetObjectArray && assetObjectArray.length > 0) {
      newAssetObj = {
        ...asset,
        ...assetObjectArray[0],
        isSalable,
        salableAssetId
      }
    }
    return newAssetObj
  })
}

export const getFormDataNewAsset = (data, assetName) => {
  const formData = new FormData()
  formData.append("file", data.file)
  formData.set("price", sb.toSatoshi(data.price))
  formData.set("asset_key", assetName)
  formData.set("asset_name", data.name)

  return formData
}

export const getAspBalancesAndRemoveFromAssetsInfo = (assetsInfo) => {
  const blankResponse = {
    aspBalance: 0,
    enerchiBalance: 0,
    filteredAssetsInfoArray: []
  }
  if (!assetsInfo) {
    return blankResponse
  }
  if (assetsInfo.length === 0) {
    return blankResponse
  }
  let aspBalance = 0
  let enerchiBalance = 0

  const filteredAssetsInfoArray = assetsInfo.map((asset) => {
    if (asset.asset === ASPIRE_COIN_ASSETS.ENERCHI) {
      enerchiBalance = sb.toBitcoin(asset.quantity)
      return
    }

    if (asset.asset === ASPIRE_COIN_ASSETS.ASP) {
      aspBalance = sb.toBitcoin(asset.quantity)
      return
    }

    return asset
  })

  return {
    aspBalance,
    enerchiBalance,
    filteredAssetsInfoArray
  }
}
