export interface OwnerDataType {
  firstName: string
  lastName: string
  addressId: string
  id: string
}

export type SalableAssetStatusType = "draft" | "published" | "exhausted"
export const SALABLE_ASSETS_STATUS = {
  DRAFT: "draft",
  PUBLISHED: "published",
  EXHAUSTED: "exhausted"
}

export interface AssetModel {
  id: number
  asset: string
  details: string
  status: SalableAssetStatusType
  user?: OwnerDataType
  price: string
}

export interface AssetExtraData {
  assetImageUrl?: string
  price?: string
  assetName?: string
}
