import { makeStyles } from "@material-ui/core/styles"

const useNotificationItemStyles: any = makeStyles((theme) => ({
  inline: {
    display: "inline"
  },
  listItemRoot: {
    backgroundColor: "rgba(0,0,0,.3)",
    color: "#ffffff"
  }
}))

export default useNotificationItemStyles
