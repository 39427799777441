import { CardContent, CardHeader, Grid, Typography } from "@material-ui/core"
import { CoinBalanceType } from "../../utils/constants"
import BaseCard from "../BaseCard"

interface CoinBalanceProps {
  balance: number
  type: CoinBalanceType
  classes: any
}

export default function CoinBalance({ balance, type, classes }: CoinBalanceProps) {
  return (
    <Grid item xs={12} md={3}>
      <BaseCard>
        <CardHeader
          title={type}
          titleTypographyProps={{ align: "center" }}
          subheaderTypographyProps={{ align: "center" }}
          className={classes.cardHeader}
        />
        <CardContent>
          <div className={classes.cardPricing}>
            <Typography component="h2" variant="h3" color="textPrimary">
              {balance}
            </Typography>
          </div>
        </CardContent>
      </BaseCard>
    </Grid>
  )
}
