import { Formik } from "formik"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"
import SpinnerAdornment from "./SpinnerAdornment"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"

export type LoginFormValues =
  | Record<string, any>
  | {
      email: string
      password: string
    }
interface LoginFormProps {
  classes: any
  onSubmit: (loginData: LoginFormValues) => void
  isLoading: boolean
}
const LoginForm = ({ classes, isLoading, onSubmit }: LoginFormProps) => {
  const { t } = useTranslation()
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validate={(values) => {
        const errors: LoginFormValues = {}
        if (!values.email) {
          errors.email = t("screens.Login.errors.email")
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = "Invalid email address"
        }
        if (!values.password) {
          errors.password = t("screens.Login.errors.password")
        } else if (values.password.length < 4) {
          errors.password = t("screens.Login.errors.passwordLength")
        }
        return errors
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        dirty
      }) => (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("screens.Login.labels.email")}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            autoComplete="email"
          />
          {errors.email && touched.email && errors.email}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label={t("screens.Login.labels.password")}
            type="password"
            id="password"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.password}
            autoComplete="current-password"
          />
          {errors.password && touched.password && errors.password}
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={(isSubmitting && isLoading) || !dirty}
          >
            {isSubmitting && isLoading ? <SpinnerAdornment /> : t("screens.Login.buttonSubmit")}
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default LoginForm
