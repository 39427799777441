import React, { useState } from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { Box, Button } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AssetModel } from "../../Models/asset"
import IpfsView from "../IpfsView/IpfsVIew"
import useAssetData from "../../hooks/useAssetData"
import VideoPlayerThumbnail from "../VideoPlayerThumbnail"
import { getIsVideoFromFileExtension } from "../../utils/constants"

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0)
  },
  total: {
    fontWeight: 700
  },
  title: {
    marginTop: theme.spacing(2)
  },
  assetImage: {
    marginBottom: theme.spacing(2),
    width: 150,
    alignSelf: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center"
  },
  doneButton: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(4)
  },
  assetImageContainer: {
    display: "flex"
  },
  videoAvatar: {
    width: 150,
    margin: "auto"
  }
}))

interface SuccessStepProps {
  asset: AssetModel
  onFinish: () => void
}

const SuccessStep = (props: SuccessStepProps) => {
  const { asset, onFinish } = props
  const classes = useStyles()
  const { assetName, assetImageUrl } = useAssetData({ assetDescription: asset.details })
  const { t } = useTranslation()
  const [isVideo, setIsVideo] = useState(false)

  React.useEffect(() => {
    if (assetImageUrl) {
      setIsVideo(getIsVideoFromFileExtension(assetImageUrl))
    }
  }, [assetImageUrl])

  return (
    <React.Fragment>
      <Typography variant="h4" align="center" gutterBottom>
        {t("components.BuyStepper.SuccessStep.title")}
      </Typography>
      <Box mt={4} display="flex" justifyContent="center" flexDirection="column">
        <div>
          {isVideo ? (
            <div className={classes.videoAvatar}>
              <VideoPlayerThumbnail src={assetImageUrl} />
            </div>
          ) : (
            <IpfsView
              rootContainerStyle={classes.assetImageContainer}
              assetImageUrl={assetImageUrl}
              imageStyle={classes.assetImage}
            />
          )}
          <Typography variant="h6" align="center" gutterBottom>
            {assetName}
          </Typography>
        </div>
        <div className={classes.doneButton}>
          <Button variant="contained" color="primary" onClick={onFinish}>
            {t("base.done")}
          </Button>
        </div>
      </Box>
    </React.Fragment>
  )
}

export default SuccessStep
