import { createTheme } from "@material-ui/core"
import { green } from "@material-ui/core/colors"

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#00ffe1"
    },
    secondary: {
      main: green[500]
    },
    type: "dark"
  }
})

export default darkTheme
