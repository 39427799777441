import { Formik } from "formik"
import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"
import SpinnerAdornment from "../SpinnerAdornment"
import TextField from "@material-ui/core/TextField"
import { Box } from "@material-ui/core"
import { ReactElement, useEffect, useRef } from "react"
import { PaymentMethodType } from "../../utils/types"

export type EnterAssetPriceFormValues =
  | Record<string, any>
  | {
      price: string
    }

interface EnterAssetPriceFormProps {
  classes: any
  onSubmit: (passphraseData: EnterAssetPriceFormValues) => void
  isLoading: boolean
  defaultPrice: number | string
  secondaryButton?: ReactElement<any, any>
  customButtonTitle?: string
  paymentMethod: PaymentMethodType
  onFetchCoinValue: (paymentMethod, price) => void
  coinValue: number
}

interface SubmitButtonProps {
  isSubmitting: boolean
  classes: any
  isLoading: boolean
  fullWidth?: boolean
  customButtonTitle?: string
}

const SubmitButton = ({
  isSubmitting,
  isLoading,
  classes,
  fullWidth = false,
  customButtonTitle
}: SubmitButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      type="submit"
      fullWidth={fullWidth}
      variant="contained"
      color="primary"
      className={classes.submit}
      disabled={isSubmitting && isLoading}
    >
      {isSubmitting && isLoading ? (
        <SpinnerAdornment />
      ) : (
        customButtonTitle || t("components.EnterAssetPriceForm.buttonSubmit")
      )}
    </Button>
  )
}

const EnterAssetPriceForm = ({
  classes,
  isLoading,
  onSubmit,
  defaultPrice,
  secondaryButton,
  customButtonTitle,
  paymentMethod,
  onFetchCoinValue,
  coinValue
}: EnterAssetPriceFormProps) => {
  const { t } = useTranslation()
  const formRef = useRef()

  const customOnChange: any = (event, setFieldValue) => {
    const split = event.target.value.toString().split(".")
    if (split && split?.length > 1) {
      if (split[1].length >= 8) {
        event.preventDefault()
        return
      }
    }
    onFetchCoinValue(paymentMethod, event.target.value)
    setFieldValue("price", event.target.value)
  }

  useEffect(() => {
    if (!formRef || !formRef.current) {
      return
    }
    // @ts-ignore
    const { values } = formRef.current
    onFetchCoinValue(paymentMethod, values.price)
  }, [paymentMethod])

  function CalcTotal(defaultPrice, coinValue) {
    const total = (defaultPrice / coinValue) * defaultPrice
    return `${total} (${paymentMethod})`
  }

  return (
    <Formik
      initialValues={{ price: defaultPrice }}
      validate={(values) => {
        const errors: EnterAssetPriceFormValues = {}
        if (!values.price) {
          errors.price = t("components.EnterAssetPriceForm.errors.priceRequired")
        }
        return errors
      }}
      onSubmit={onSubmit}
      innerRef={formRef}
    >
      {({ values, errors, touched, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            type="number"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputProps={{ inputProps: { min: 0, step: ".5" } }}
            id="price"
            label={t("components.EnterAssetPriceForm.labels.price")}
            name="price"
            onChange={(e) => customOnChange(e, setFieldValue)}
            onBlur={handleBlur}
            error={!!errors.price}
            helperText={errors.price && touched.price && errors.price}
            value={values.price}
          />
          {/*`${coinValue} (${paymentMethod})`*/}
          {CalcTotal(values.price, coinValue)}
          {secondaryButton ? (
            <Box display="flex" mt={6} justifyContent="center">
              {secondaryButton}
              <SubmitButton
                isSubmitting={isSubmitting}
                isLoading={isLoading}
                classes={classes}
                customButtonTitle={customButtonTitle}
              />
            </Box>
          ) : (
            <SubmitButton
              isSubmitting={isSubmitting}
              fullWidth
              isLoading={isLoading}
              classes={classes}
              customButtonTitle={customButtonTitle}
            />
          )}
        </form>
      )}
    </Formik>
  )
}

export default EnterAssetPriceForm
