import {
  Avatar,
  Divider,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from "@material-ui/core"
import React, { useState } from "react"
import useNotificationItemStyles from "./styles"
import { NotificationModel } from "../../Models/notification"
import { getOwnerFullName } from "../../utils/userHelper"
import useAssetData from "../../hooks/useAssetData"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import { getIsVideoFromFileExtension } from "../../utils/constants"
import VideoPlayerThumbnail from "../VideoPlayerThumbnail"

interface NotificationItemProps {
  notification: NotificationModel
  onAcceptNotification: (notification: NotificationModel, assetExtraData: any) => void
  onRejectNotification: (notification: NotificationModel) => void
}

const NotificationItem = (props: NotificationItemProps) => {
  const classes: any = useNotificationItemStyles()
  const { notification, onAcceptNotification, onRejectNotification } = props
  if (!notification) {
    return null
  }

  const { details } = notification

  const assetExtraData = useAssetData({
    assetDescription: details
  })
  const { assetName, assetImageUrl } = assetExtraData
  const [isVideo, setIsVideo] = useState(false)

  React.useEffect(() => {
    if (assetImageUrl) {
      setIsVideo(getIsVideoFromFileExtension(assetImageUrl))
    }
  }, [assetImageUrl])

  return (
    <>
      <ListItem classes={{ root: classes.listItemRoot }} alignItems="flex-start">
        <ListItemAvatar>
          {isVideo ? (
            <Avatar>
              <VideoPlayerThumbnail src={assetImageUrl} />
            </Avatar>
          ) : (
            <Avatar src={assetImageUrl} />
          )}
        </ListItemAvatar>
        <ListItemText
          primary={assetName}
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
                {getOwnerFullName(notification.buyerData)}
              </Typography>
              {` — Wants to buy for ${notification.paymentPrice} ${notification.paymentMethod} ~ (${notification.price} USD)`}
            </React.Fragment>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            onClick={() => onAcceptNotification(notification, assetExtraData)}
            edge="start"
            aria-label="accept"
          >
            <CheckIcon />
          </IconButton>
          <IconButton
            onClick={() => onRejectNotification(notification)}
            edge="end"
            aria-label="decline"
          >
            <CloseIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <Divider variant="inset" component="li" />
    </>
  )
}

export default NotificationItem
