import { BaseApi } from "../base-api"
import { ASPIRE_API_METHODS, AUTH_CONFIG, getRpcData } from "../../utils/constants"
import axios from "axios"

export class AssetsService extends BaseApi {
  fetchAssetsFilter = async (assetsAddresses) => {
    const params = {
      walletAddresses: assetsAddresses
    }
    return await this.apisauce.post(this.config.api.paths.walletUsers, params)
  }

  fetchAssetsInfo = async (assetsNames) => {
    const params = {
      assets: assetsNames
    }
    return await this.apisauce.post(
      this.config.api.paths.aspireApi,
      getRpcData(ASPIRE_API_METHODS.GET_ASSETS_INFO, params),
      {
        ...AUTH_CONFIG
      }
    )
  }

  fetchAllAssets = async (page, search = "") => {
    if (search && search !== "") {
      return await this.apisauce.get(
        `${this.config.api.paths.salableAssets}?name=${search}&page=${page}`
      )
    }
    return await this.apisauce.get(`${this.config.api.paths.salableAssets}?page=${page}`)
  }

  createIssuance = async (data) => {
    const params = {
      ...data,
      divisible: false
    }
    return await this.apisauce.post(
      this.config.api.paths.aspireApi,
      getRpcData(ASPIRE_API_METHODS.CREATE_ISSUANCE, params),
      {
        ...AUTH_CONFIG
      }
    )
  }

  signRawTransaction = async (unsignedHex, passphrase, shouldSend = false) => {
    const params = {
      unsignedHex,
      passphrase,
      shouldSend
    }
    return await this.apisauce.post(this.config.api.paths.nodeSignRaw, params)
  }

  uploadAndPinData = async (data) => {
    return await axios.post(this.config.api.paths.nodeUploadAndPinData, data)
  }

  verifyAssetName = async (name) => {
    const params = {
      filters: {
        field: "asset",
        op: "==",
        value: name
      },
      status: "valid"
    }
    return await this.apisauce.post(
      this.config.api.paths.aspireApi,
      getRpcData(ASPIRE_API_METHODS.GET_ISSUANCES, params),
      {
        ...AUTH_CONFIG
      }
    )
  }
}
