import React from "react"
import { HashRouter as Router, Switch, Route, useHistory } from "react-router-dom"
import Home from "../containers/Home"
import Login from "../containers/Login"
import AssetDetail from "../containers/AssetDetail"
import SignUp from "../containers/SignUp"
import Wallet from "../containers/Wallet"
import ResetPassword from "../containers/ResetPassword"
import { useSelector } from "react-redux"
import NewAsset from "../containers/NewAsset"
import Notifications from "../containers/Notifications"
import { GlobalState } from "../store/reducers"

function AdminGuardedRoute(user: { id: number } | null) {
  return function Router(props: any) {
    const { component: Component, ...rest } = props
    const history = useHistory()
    React.useEffect(() => {
      if (!user) {
        history.push("/login")
      }
    })
    return <Route {...rest} render={(props) => (!!user ? <Component {...props} /> : <div />)} />
  }
}

const RootNavigator = () => {
  const { user } = useSelector((state: GlobalState) => state.authReducer)
  const AdminRoute = AdminGuardedRoute(user)

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/assetDetail" component={AssetDetail} />
          <AdminRoute path="/wallet" component={Wallet} />
          <Route path="/login" component={Login} />
          <Route path="/signUp" component={SignUp} />
          <Route path="/reset" component={ResetPassword} />
          <AdminRoute path="/newAsset" component={NewAsset} />
          <AdminRoute path="/notifications" component={Notifications} />
        </Switch>
      </div>
    </Router>
  )
}

export default RootNavigator
