import { makeStyles } from "@material-ui/core"

const drawerWidth = 240

const useAppBarStyles: any = makeStyles((theme) => ({
  appBar: {
    transition: theme.transitions.create(["width", "margin"], {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    }),
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "white",
    borderBottomStyle: "solid",
    borderBottomWidth: 11,
    borderBottomColor: "#00ffe1"
  },
  accountRow: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  },
  appBarShift: {
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["width", "margin"], {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp
    }),
    width: `calc(100% - ${drawerWidth}px)`
  },
  appBarSpacer: theme.mixins.toolbar,
  drawerPaper: {
    position: "relative",
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.sharp
    }),
    whiteSpace: "nowrap",
    width: drawerWidth
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.sharp
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9)
    }
  },
  fixedHeight: {
    height: 240
  },
  title: {
    flexGrow: 1
  },
  titleHome: {
    textDecoration: "none",
    color: "black"
  },
  titleProject: {
    textDecoration: "none",
    paddingLeft: theme.spacing(4),
    justifyContent: "flex-start",
    display: "flex"
  },
  logoContainer: {
    display: "flex",
    flex: 1
  },
  logo: {
    width: theme.spacing(10)
  }
}))

export default useAppBarStyles
