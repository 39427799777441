import { makeStyles } from "@material-ui/core/styles"

const useHomeStyles: any = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4)
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  fixedHeight: {
    height: 240
  },
  menuButton: {
    marginRight: 36
  },
  menuButtonHidden: {
    display: "none"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    padding: theme.spacing(2)
  },
  root: {
    display: "flex"
  },
  title: {
    flexGrow: 1
  },
  titleHome: {
    textDecoration: "none",
    color: "black"
  },
  rootPagination: {
    display: "flex",
    marginTop: theme.spacing(4),
    justifyContent: "center"
  },
  ulPagination: {
    "& .MuiPaginationItem-root": {
      color: "#fff",
      backgroundColor: "rgba(0,0,0,.3)"
    }
  },
  form: {
    marginBottom: theme.spacing(2)
  }
}))

export default useHomeStyles
