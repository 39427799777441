import { makeStyles } from "@material-ui/core/styles"
import { red } from "@material-ui/core/colors"

const useNotificationsStyles: any = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4)
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  root: {
    display: "flex"
  },
  avatar: {
    backgroundColor: red[500],
    marginRight: 10
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[700]
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2)
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    flex: 1
  },
  contentGrid: {
    marginTop: theme.spacing(2)
  }
}))

export default useNotificationsStyles
