import { HIDE_DIALOG, SHOW_DIALOG } from "../actions/dialog"

const initialState = {
  visible: false,
  title: "",
  subtitle: "",
  okButtonText: "base.ok"
}

const dialogReducer = function (state = initialState, action) {
  switch (action.type) {
    case SHOW_DIALOG:
      const { title, subtitle, okButtonText } = action.payload
      return {
        ...state,
        visible: true,
        title,
        subtitle,
        okButtonText
      }
    case HIDE_DIALOG:
      return {
        ...state,
        visible: false,
        title: "",
        subtitle: "",
        okButtonText: "base.ok"
      }
    default:
      return state
  }
}

export default dialogReducer
