import { Formik } from "formik"
import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"
import { Grid, Input, Typography } from "@material-ui/core"
import { useState } from "react"
import TextField from "@material-ui/core/TextField"
import {
  getIsVideoFromFileExtension,
  MAX_DURATION_OF_FILE,
  validateFileExtension,
  validFileExtensions
} from "../../utils/constants"
import VideoPlayer from "../VideoPlayer"

export type NewAssetFormValues =
  | Record<string, any>
  | {
      file: any
      name: string
      price: number
      quantity: number
    }

const initialValues: NewAssetFormValues = {
  file: undefined,
  name: "",
  price: "",
  quantity: 1
}
interface NewAssetFormProps {
  classes: any
  onSubmit: (loginData: NewAssetFormValues) => void
  onError: (title, subtitle) => void
}
const NewAssetForm = ({ classes, onSubmit, onError }: NewAssetFormProps) => {
  const { t } = useTranslation()
  const [imageDisplay, setImageDisplay] = useState(null)
  const [isVideo, setIsVideo] = useState(false)
  const [durationExceeded, setDurationExceeded] = useState(false)

  const onChangeFile = (event, setFieldValue) => {
    const reader = new FileReader()
    const file = event.target["files"][0]
    if (!file) {
      return
    }

    const sFileName = file.name
    setIsVideo(getIsVideoFromFileExtension(sFileName))
    const fileSize = file.size

    if (sFileName.length > 0) {
      const fileTypeValid = validateFileExtension(sFileName)

      if (!fileTypeValid) {
        onError(
          t("screens.NewAsset.errors.fileTypeInvalid", { fileName: sFileName }),
          t("screens.NewAsset.errors.fileTypeInvalidSubtitle", {
            allowedExtensions: validFileExtensions.join(", ")
          })
        )
        event.target.value = null
        return
      }
    }

    if (fileSize > 8000000) {
      onError(
        t("screens.NewAsset.errors.fileSizeInvalidTitle", { fileName: sFileName }),
        t("screens.NewAsset.errors.fileSizeInvalidSubtitle")
      )
      event.target.value = null
      return
    }

    const isVideo = getIsVideoFromFileExtension(sFileName)
    if (isVideo) {
      const video = document.createElement("video")
      video.preload = "metadata"
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        const { duration } = video
        if (duration > MAX_DURATION_OF_FILE) {
          onError(
            t("screens.NewAsset.errors.videoDurationExceededTitle", { fileName: sFileName }),
            t("screens.NewAsset.errors.videoDurationExceededSubtitle", {
              maxDuration: MAX_DURATION_OF_FILE
            })
          )
          event.target.value = null
          setDurationExceeded(true)
          return
        }
      }
      video.src = URL.createObjectURL(file)
    }

    reader.onloadend = () => {
      setImageDisplay({
        file: reader.result
      })
    }
    reader.readAsDataURL(file)
    setFieldValue("file", file)
  }
  return (
    <Formik
      initialValues={initialValues}
      validate={(values) => {
        const errors: NewAssetFormValues = {}
        if (!values.file) {
          errors.file = t("screens.NewAsset.errors.fileRequired")
        }
        if (!values.name) {
          errors.name = t("screens.NewAsset.errors.nameRequired")
        }
        if ((!values.price && Number(values.price) !== 0) || values.price === "") {
          errors.price = t("screens.NewAsset.errors.priceRequired")
        } else if (Number(values.price) === 0) {
          errors.price = t("screens.NewAsset.errors.priceMin")
        }
        if (!values.quantity || values.quantity === "") {
          errors.quantity = t("screens.NewAsset.errors.quantityRequired")
        } else if (Number(values.quantity) < 0) {
          errors.quantity = t("screens.NewAsset.errors.quantityMin")
        }
        return errors
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        dirty,
        setFieldValue
      }) => (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          {isVideo && !durationExceeded ? (
            <VideoPlayer src={imageDisplay?.file} />
          ) : (
            <img src={imageDisplay?.file} alt="" className={classes.imageDisplay} />
          )}
          <Input
            type="file"
            required
            fullWidth
            id="file"
            placeholder={t("screens.NewAsset.labels.file")}
            name="file"
            onChange={(event) => onChangeFile(event, setFieldValue)}
            onBlur={handleBlur}
          />
          <Typography component="h6" variant="h6" color="textPrimary">
            Valid extensions: {validFileExtensions.join(", ")}
          </Typography>
          {errors.file && touched.file && errors.file}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="name"
            label={t("screens.NewAsset.labels.name")}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
          />
          {errors.name && touched.name && errors.name}
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="price"
                  label={t("screens.NewAsset.labels.price")}
                  type="number"
                  id="price"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.price}
                />
                {errors.price && touched.price && errors.price}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="quantity"
                  label={t("screens.NewAsset.labels.quantity")}
                  type="number"
                  id="quantity"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.quantity}
                />
                {errors.quantity && touched.quantity && errors.quantity}
              </Grid>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!dirty}
          >
            {t("screens.NewAsset.buttonSubmit")}
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default NewAssetForm
