import firebase from "firebase"
import { BaseApi } from "../base-api"

export class AuthService extends BaseApi {
  async signInWithEmailAndPassword(email, password) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        return userCredential.user.getIdTokenResult(true)
      })
  }

  async resetPassword(email) {
    return firebase.auth().sendPasswordResetEmail(email)
  }

  async signUpWithFirebase(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password)
  }

  putProfile = async (generatedAddress?, firstName?, lastName?) => {
    return await this.apisauce.put(this.config.api.paths.profile, {
      firstName,
      lastName,
      walletAddress: generatedAddress
    })
  }

  getProfile = async () => {
    return await this.apisauce.get(this.config.api.paths.profile, {})
  }

  async logoutFromFirebase() {
    return firebase.auth().signOut()
  }
}
