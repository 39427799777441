import { call, put, takeLatest } from "redux-saga/effects"
import {
  LOGIN_START,
  loginFailed,
  loginSuccess,
  LOGOUT,
  logoutFailed,
  logoutSuccess,
  RESET_PASSWORD_START,
  resetPasswordFailed,
  resetPasswordSuccess,
  SIGN_UP_START,
  signUpFailed,
  signUpSuccess
} from "../actions/auth"
import { showDialog } from "../actions/dialog"
import {
  FIREBASE_RESET_PASSWORD_ERROR_CODES,
  FIREBASE_SIGN_UP_ERROR_CODES
} from "../../utils/constants"
import { getContext } from "redux-saga/effects"
import { CWHierarchicalKey } from "../../utils/util.bitcore"

export function* watchAuthStart() {
  yield takeLatest(LOGIN_START, firebaseSignInEmailAndPassword)
  yield takeLatest(SIGN_UP_START, firebaseSignUp)
  yield takeLatest(LOGOUT, firebaseLogout)
  yield takeLatest(RESET_PASSWORD_START, resetPassword)
}

function* firebaseSignInEmailAndPassword({ payload }: ReturnType<any>) {
  try {
    const { email, password, callback } = payload
    const environment = yield getContext("env")
    yield call(environment.api.authService.signInWithEmailAndPassword, email, password)
    const response = yield call(environment.api.authService.getProfile)
    const user = response.data.data
    yield put(loginSuccess(user))
    callback()
  } catch (e) {
    yield put(loginFailed())
    yield put(
      showDialog(
        "screens.Login.errors.signInFailed",
        "screens.Login.errors.emailOrPasswordInvalid",
        "base.ok"
      )
    )
  }
}

function* resetPassword({ payload }: ReturnType<any>) {
  try {
    const { email, callback } = payload
    const environment = yield getContext("env")
    yield call(environment.api.authService.resetPassword, email)
    yield put(resetPasswordSuccess())
    callback()
  } catch (e) {
    let subtitle = "screens.ResetPassword.errors.resetPasswordFailed"
    if (e.code === FIREBASE_RESET_PASSWORD_ERROR_CODES.USER_NOT_FOUND) {
      subtitle = "screens.ResetPassword.errors.userNotFound"
    }
    yield put(resetPasswordFailed())
    yield put(showDialog("screens.ResetPassword.errors.resetPasswordFailed", subtitle, "base.ok"))
  }
}

function* firebaseSignUp({ payload }: ReturnType<any>) {
  try {
    const { firstName, lastName, email, password, passphrase, callback } = payload
    const environment = yield getContext("env")
    yield call(environment.api.authService.signUpWithFirebase, email, password)
    const cw = new CWHierarchicalKey(passphrase)
    const walletAddress = cw.getAddressKey(0).getAddress()
    const response = yield call(
      environment.api.authService.putProfile,
      walletAddress,
      firstName,
      lastName
    )
    const user = response.data.data
    yield put(signUpSuccess(user))
    callback()
  } catch (error) {
    const errorCode = error.code
    let errorTitle: string
    let errorSubtitle: string
    switch (errorCode) {
      case FIREBASE_SIGN_UP_ERROR_CODES.EMAIL_IN_USE:
        errorTitle = "screens.SignUp.errors.defaultErrorTitle"
        errorSubtitle = "screens.SignUp.errors.emailExists"
        break
      case FIREBASE_SIGN_UP_ERROR_CODES.INVALID_EMAIL:
        errorTitle = "screens.SignUp.errors.defaultErrorTitle"
        errorSubtitle = "screens.SignUp.errors.emailInvalid"
        break
      case FIREBASE_SIGN_UP_ERROR_CODES.WEAK_PASSWORD:
        errorTitle = "screens.SignUp.errors.defaultErrorTitle"
        errorSubtitle = "screens.SignUp.errors.weakPassword"
        break
      case FIREBASE_SIGN_UP_ERROR_CODES.OPERATION_NOT_ALLOWED:
        errorTitle = "screens.SignUp.errors.defaultErrorTitle"
        errorSubtitle = "screens.SignUp.errors.operationNotAllowed"
        break
      default:
        errorTitle = "screens.SignUp.errors.unexpectedErrorTitle"
        errorSubtitle = "screens.SignUp.errors.unexpectedError"
    }
    yield put(signUpFailed())
    yield put(showDialog(errorTitle, errorSubtitle, "base.ok"))
  }
}

function* firebaseLogout({ payload }: ReturnType<any>) {
  try {
    const { callback } = payload
    const environment = yield getContext("env")
    yield call(environment.api.services.authService.logoutFromFirebase)
    yield put(logoutSuccess())
    callback()
  } catch (e) {
    const { callback } = payload
    yield put(logoutFailed())
    callback()
  }
}
