import { BaseApi } from "../base-api"
import { ASPIRE_API_METHODS, AUTH_CONFIG, getRpcData } from "../../utils/constants"

export class WalletService extends BaseApi {
  getWalletBalances = async (walletAddress) => {
    const params = {
      orderBy: "asset",
      orderDir: "desc",
      filters: [{ field: "address", op: "==", value: walletAddress }]
    }
    return await this.apisauce.post(
      this.config.api.paths.aspireApi,
      getRpcData(ASPIRE_API_METHODS.GET_BALANCES, params),
      {
        ...AUTH_CONFIG
      }
    )
  }

  sellAsset = async (params) => {
    return await this.apisauce.post(this.config.api.paths.salableAssets, params)
  }

  getMySalableAssets = async (userId) => {
    return await this.apisauce.get(this.config.api.paths.salableAssets, { user_id: userId })
  }

  updateSalableAssetStatus = async (salableAssetId, status) => {
    return await this.apisauce.put(`${this.config.api.paths.salableAssets}/${salableAssetId}`, {
      status
    })
  }

  getBTCBalances = async (address) => {
    return await this.apisauce.post(this.config.api.paths.nodeGetBalances, { address })
  }
}
