import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import TextField from "@material-ui/core/TextField"
import debounce from "lodash.debounce"

export type SearchBarValues =
  | Record<string, any>
  | {
      search: string
    }

interface SearchBarProps {
  classes: any
  onSubmit: (searchData: SearchBarValues) => void
  isLoading: boolean
}

const SearchBar = ({ classes, onSubmit }: SearchBarProps) => {
  const { t } = useTranslation()
  const debouncedSearch = debounce((value) => onSubmit({ search: value }), 1000)

  const customOnChange: any = (event, setFieldValue) => {
    const value = event.target.value
    setFieldValue("search", value)
    if (value.length < 3 && value !== "") {
      return
    }
    debouncedSearch(value)
  }

  return (
    <Formik initialValues={{ search: "" }} onSubmit={onSubmit}>
      {({ values, errors, touched, handleBlur, handleSubmit, setFieldValue }) => (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            type="text"
            variant="outlined"
            margin="normal"
            fullWidth
            id="search"
            label={t("components.SearchBar.searchLabel")}
            name="search"
            onChange={(e) => customOnChange(e, setFieldValue)}
            onBlur={handleBlur}
            error={!!errors.search}
            helperText={errors.search && touched.search && errors.search}
            value={values.search}
          />
        </form>
      )}
    </Formik>
  )
}

export default SearchBar
