import { useBaseCardClasses } from "./styles"
import { ReactElement } from "react"
import { Card } from "@material-ui/core"
import { CardProps } from "@material-ui/core/Card/Card"

interface BaseCardProps extends CardProps {
  children: ReactElement | ReactElement[]
  className?: any
}

export default function BaseCard({ children, className = "" }: BaseCardProps) {
  const classes: any = useBaseCardClasses()

  return <Card className={`${classes.rootCard} ${className}`}>{children}</Card>
}
