import { Button, CardActions, CardContent, CardHeader, Grid, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { BalanceModel } from "../../Models/balance"
import StarIcon from "@material-ui/icons/StarOutlined"
import useAssetData from "../../hooks/useAssetData"
import IpfsView from "../IpfsView/IpfsVIew"
import { ASPIRE_COIN_ASSETS, getIsVideoFromFileExtension } from "../../utils/constants"
import React, { useState } from "react"
import VideoPlayerThumbnail from "../VideoPlayerThumbnail"
import BaseCard from "../BaseCard"

interface BalanceCardProps {
  classes: any
  coinBalance: BalanceModel
  onSellPress: (coinBalance: BalanceModel) => void
  onRemoveSalePress: (salableAssetId) => void
  skeletonHeight?: number
}

export default function BalanceCard({
  classes,
  coinBalance,
  onSellPress,
  onRemoveSalePress,
  skeletonHeight
}: BalanceCardProps) {
  const { t } = useTranslation()
  const { assetName, assetImageUrl } = useAssetData({
    assetDescription: coinBalance?.description || null
  })
  const balance = coinBalance.quantity
  const [isVideo, setIsVideo] = useState(false)

  React.useEffect(() => {
    if (assetImageUrl) {
      setIsVideo(getIsVideoFromFileExtension(assetImageUrl))
    }
  }, [assetImageUrl])

  return (
    <>
      <Grid
        item
        key={coinBalance.asset}
        xs={12}
        sm={coinBalance.asset === ASPIRE_COIN_ASSETS.ENERCHI ? 12 : 6}
        md={coinBalance.asset === ASPIRE_COIN_ASSETS.ENERCHI ? 3 : 4}
      >
        <BaseCard>
          {isVideo ? (
            <div className={classes.videoAvatar}>
              <VideoPlayerThumbnail src={assetImageUrl} />
            </div>
          ) : (
            <IpfsView
              assetImageUrl={assetImageUrl}
              imageStyle={classes.media}
              skeletonHeight={skeletonHeight}
            />
          )}
          <CardHeader
            title={assetName}
            subheader={coinBalance.asset}
            titleTypographyProps={{ align: "center" }}
            subheaderTypographyProps={{ align: "center" }}
            action={coinBalance.isSalable ? <StarIcon /> : null}
            className={classes.cardHeader}
          />
          <CardContent>
            <div className={classes.cardPricing}>
              <Typography component="h2" variant="h3" color="textPrimary">
                {balance}
              </Typography>
            </div>
          </CardContent>
          <CardActions>
            {!coinBalance.isSalable ? (
              <Button
                fullWidth
                variant="outlined"
                onClick={() => onSellPress({ ...coinBalance, assetName })}
                color="primary"
                disabled={!balance || balance <= 0}
              >
                {t("screens.Wallet.sellAsset")}
              </Button>
            ) : (
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => onRemoveSalePress(coinBalance.salableAssetId)}
              >
                {t("screens.Wallet.removeAssetOfSale")}
              </Button>
            )}
          </CardActions>
        </BaseCard>
      </Grid>
    </>
  )
}
