import { CWHierarchicalKey } from "./util.bitcore"

export const validatePassphrase = (passphrase, userWalletAddress) => {
  let address = ""
  try {
    const cwk = new CWHierarchicalKey(passphrase)
    const addressKey = cwk.getAddressKey(0)
    address = addressKey.getAddress()
  } catch {
    address = ""
  }

  return address === userWalletAddress
}
