import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"
import Mnemonic from "utils/Mnemonic"
import { Paper, Popover, Typography } from "@material-ui/core"
import SpinnerAdornment from "../SpinnerAdornment"
import React from "react"
import ContentCopy from "@material-ui/icons/FileCopy"

interface PassphraseItemProps {
  classes: any
  onSubmit: (passphrase: string) => void
  isLoading: boolean
}
const PassphraseItem = ({ onSubmit, classes, isLoading }: PassphraseItemProps) => {
  const { t } = useTranslation()
  const [passphrase, setPassphrase] = React.useState(null)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    const textField = document.createElement("textarea")
    textField.innerText = passphrase
    document.body.appendChild(textField)
    textField.select()
    document.execCommand("copy")
    textField.remove()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  React.useEffect(() => {
    const m = new Mnemonic(128)
    const words = m.toWords()
    const passphrase: string = words.join(" ")
    setPassphrase(passphrase)
  }, [])

  const onSubmitInternal = () => {
    onSubmit(passphrase)
  }

  return (
    <Paper className={classes.paperPassphrase}>
      <Typography variant="h6" align="center" gutterBottom>
        {t("components.PassphraseItem.textPassphraseRecommendations")}
      </Typography>
      <Typography variant="body1" color="primary" gutterBottom>
        {passphrase}
      </Typography>
      <Button aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
        <ContentCopy />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
      >
        <Typography className={classes.typography}>Copied!</Typography>
      </Popover>
      <Button
        onClick={onSubmitInternal}
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={isLoading || !passphrase}
      >
        {isLoading ? <SpinnerAdornment /> : t("components.PassphraseItem.buttonSubmit")}
      </Button>
    </Paper>
  )
}

export default PassphraseItem
