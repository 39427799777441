export interface SellerDataModel {
  firstName: string
  lastName: string
  walletAddress: string
}

export interface BuyerDataModel {
  firstName: string
  lastName: string
  walletAddress: string
}
export type NotificationStatusType = "pending" | "confirmed" | "rejected" | "expired"
export const NOTIFICATION_STATUS = {
  PENDING: "pending",
  CONFIRMED: "confirmed",
  REJECTED: "rejected",
  EXPIRED: "expired"
}

export interface NotificationModel {
  id: number
  details: string
  encryptedData: string
  assetName: string
  status: string
  expiresAt: string
  price: string
  paymentPrice: string
  paymentMethod: string
  buyerData: BuyerDataModel
  sellerData: SellerDataModel
}
