import CryptoJS from "crypto-js"

const crypt = {
  // (B1) THE SECRET KEY
  secret: process.env.REACT_APP_CRYPT_SECRET_KEY,

  // (B2) ENCRYPT
  encrypt: function (clear) {
    let cipher = CryptoJS.AES.encrypt(clear, crypt.secret)
    cipher = cipher.toString()
    return cipher
  },

  // (B3) DECRYPT
  decrypt: function (cipher) {
    let decipher = CryptoJS.AES.decrypt(cipher, crypt.secret)
    decipher = decipher.toString(CryptoJS.enc.Utf8)
    return decipher
  }
}

export default crypt
