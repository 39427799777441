import { Container, List, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import useNotificationsListStyles from "./styles"
import { NotificationModel } from "../../Models/notification"
import NotificationItem from "../NotificationItem"
import BasePaper from "../BasePaper"

interface NotificationsListProps {
  notifications: Array<NotificationModel>
  onAcceptNotification: (notification: NotificationModel, assetExtraData: any) => void
  onRejectNotification: (notification: NotificationModel) => void
}

const NotificationsList = (props: NotificationsListProps) => {
  const classes: any = useNotificationsListStyles()
  const { notifications, onAcceptNotification, onRejectNotification } = props
  const { t } = useTranslation()

  if (!notifications || notifications.length === 0) {
    return (
      <div className={classes.paperContainer}>
        <BasePaper className={classes.paper}>
          <Container>
            <Typography variant="h5">{t("screens.Notifications.notificationsEmpty")}</Typography>
          </Container>
        </BasePaper>
      </div>
    )
  }

  return (
    <List className={classes.root}>
      {notifications.map((notification: NotificationModel) => (
        <NotificationItem
          onAcceptNotification={onAcceptNotification}
          onRejectNotification={onRejectNotification}
          key={notification.id}
          notification={notification}
        />
      ))}
    </List>
  )
}

export default NotificationsList
