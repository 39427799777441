import { useState } from "react"
import Avatar from "@material-ui/core/Avatar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Box from "@material-ui/core/Box"
import LockIcon from "@material-ui/icons/Lock"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { signUpStart } from "../../store/actions/auth"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import SignUpForm, { SignUpValues } from "../../components/SIgnUpForm"
import Footer from "../../components/Footer"
import Link from "@material-ui/core/Link"
import PassphraseItem from "../../components/PassphraseItem"
import { GlobalState } from "../../store/reducers"
import BaseContainer from "../../components/BaseContainer"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  alert: {
    marginBottom: theme.spacing(2)
  },
  paperPassphrase: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  typography: {
    padding: theme.spacing(1)
  },
  root: {
    display: "flex"
  },
  link: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  container: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  }
}))

const SignUp = () => {
  const classes = useStyles()
  const history: any = useHistory()
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: GlobalState) => state.authReducer)
  const { t } = useTranslation()
  const [signUpData, setSignUpData] = useState(null)

  const goHome = () => {
    history.push("/")
  }

  const goToLogin = () => {
    history.push("/login")
  }

  const goToSignUp = () => {
    setSignUpData(null)
  }

  const onSignUpSubmit = (signUpData: SignUpValues) => {
    setSignUpData(signUpData)
  }

  const signUpSubmit = (passphrase) => {
    dispatch(signUpStart(signUpData, passphrase, goHome))
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <BaseContainer>
        <Container maxWidth="xs" className={classes.container}>
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("screens.SignUp.title")}
            </Typography>
            {!signUpData ? (
              <>
                <SignUpForm classes={classes} isLoading={isLoading} onSubmit={onSignUpSubmit} />
                <Link onClick={goToLogin} variant="body2" className={classes.link} color="inherit">
                  {t("screens.SignUp.signInLink")}
                </Link>
              </>
            ) : (
              <>
                <PassphraseItem classes={classes} isLoading={isLoading} onSubmit={signUpSubmit} />
                <Link onClick={goToSignUp} variant="body2" className={classes.link} color="inherit">
                  {t("screens.SignUp.title")}
                </Link>
              </>
            )}
          </div>
        </Container>
        <Box mt={8}>
          <Footer />
        </Box>
      </BaseContainer>
    </div>
  )
}

export default SignUp
