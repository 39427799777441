export const FETCH_BALANCES_START = "FETCH_BALANCES_START"
export const FETCH_BALANCES_SUCCESS = "FETCH_BALANCES_SUCCESS"
export const FETCH_BALANCES_FAILED = "FETCH_BALANCES_FAILED"
export const UPDATE_COINS_BALANCES = "UPDATE_COINS_BALANCES"
export const SELL_ASSET_START = "SELL_ASSET_START"
export const SELL_ASSET_SUCCESS = "SELL_ASSET_SUCCESS"
export const SELL_ASSET_FAILED = "SELL_ASSET_FAILED"
export const REMOVE_SALABLE_ASSET_FROM_SALE = "REMOVE_SALABLE_ASSET_FROM_SALE"

export const fetchBalancesStart = () => ({
  type: FETCH_BALANCES_START
})

export const fetchBalancesSuccess = (assets) => ({
  type: FETCH_BALANCES_SUCCESS,
  payload: assets
})

export const fetchBalancesFailed = () => ({
  type: FETCH_BALANCES_FAILED
})

export const updateCoinsBalances = (aspBalance, gaspBalance, enerchiBalance) => ({
  type: UPDATE_COINS_BALANCES,
  payload: {
    aspBalance,
    gaspBalance,
    enerchiBalance
  }
})

export const sellAssetStart = (data) => ({
  type: SELL_ASSET_START,
  payload: data
})

export const sellAssetSuccess = () => ({
  type: SELL_ASSET_SUCCESS
})

export const sellAssetFailed = () => ({
  type: SELL_ASSET_FAILED
})

export const removeSalableAssetFromSale = (salableAssetId, callback) => ({
  type: REMOVE_SALABLE_ASSET_FROM_SALE,
  payload: {
    salableAssetId,
    callback
  }
})
