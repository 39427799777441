import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Trans, useTranslation } from "react-i18next"
import Button from "@material-ui/core/Button"
import useBaseConfirmDialogStyles from "./styles"

interface BaseConfirmDialogProps {
  visible: boolean
  title: string
  subtitle?: string
  okButtonText: string
  okButtonPress: () => void
  cancelButtonText: string
  onClose: () => void
}

const BaseConfirmDialog = ({
  visible,
  title,
  subtitle,
  okButtonText,
  okButtonPress,
  cancelButtonText,
  onClose
}: BaseConfirmDialogProps) => {
  const { t } = useTranslation()
  const classes = useBaseConfirmDialogStyles()

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Trans t={t}>{title}</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Trans t={t}>{subtitle}</Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={classes}>
        <Button
          variant="contained"
          onClick={okButtonPress}
          color="primary"
          className={classes.submit}
        >
          {okButtonText}
        </Button>
        <Button variant="contained" onClick={onClose} color="default" className={classes.submit}>
          {cancelButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BaseConfirmDialog
