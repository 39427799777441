import { useBasePaperClasses } from "./styles"
import { ReactElement } from "react"
import { Paper, PaperProps } from "@material-ui/core"

interface BasePaperProps extends PaperProps {
  children: ReactElement | ReactElement[]
  className?: any
}

export default function BasePaper({ children, className = "" }: BasePaperProps) {
  const classes: any = useBasePaperClasses()

  return <Paper className={`${classes.rootPaper} ${className}`}>{children}</Paper>
}
