import { Formik } from "formik"
import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"
import SpinnerAdornment from "../SpinnerAdornment"
import TextField from "@material-ui/core/TextField"
import { Box } from "@material-ui/core"
import { ReactElement } from "react"
import { validatePassphrase } from "../../utils/passphraseHelper"

export type EnterPassphraseFormValues =
  | Record<string, any>
  | {
      passphrase: string
    }

interface EnterPassphraseFormProps {
  classes: any
  onSubmit: (passphraseData: EnterPassphraseFormValues) => void
  isLoading: boolean
  secondaryButton?: ReactElement<any, any>
  customButtonTitle?: string
  walletAddress: string
}

interface SubmitButtonProps {
  isSubmitting: boolean
  classes: any
  isLoading: boolean
  dirty: boolean
  fullWidth?: boolean
  customButtonTitle?: string
}

const SubmitButton = ({
  isSubmitting,
  dirty,
  isLoading,
  classes,
  fullWidth = false,
  customButtonTitle
}: SubmitButtonProps) => {
  const { t } = useTranslation()
  return (
    <Button
      type="submit"
      fullWidth={fullWidth}
      variant="contained"
      color="primary"
      className={classes.submit}
      disabled={(isSubmitting && isLoading) || !dirty}
    >
      {isSubmitting && isLoading ? (
        <SpinnerAdornment />
      ) : (
        customButtonTitle || t("components.EnterPassphraseForm.buttonSubmit")
      )}
    </Button>
  )
}

const EnterPassphraseForm = ({
  classes,
  isLoading,
  onSubmit,
  secondaryButton,
  customButtonTitle,
  walletAddress
}: EnterPassphraseFormProps) => {
  const { t } = useTranslation()
  const onSubmitInternal = (data, { setErrors }) => {
    const valid = validatePassphrase(data.passphrase, walletAddress)
    if (!valid) {
      const errors: EnterPassphraseFormValues = {}
      errors.passphrase = t("components.EnterPassphraseForm.errors.incorrectPassphrase")
      setErrors(errors)
      return
    }

    onSubmit(data)
  }

  return (
    <Formik
      initialValues={{ passphrase: "" }}
      validate={(values) => {
        const errors: EnterPassphraseFormValues = {}
        if (!values.passphrase) {
          errors.passphrase = t("components.EnterPassphraseForm.errors.passphraseRequired")
        }
        return errors
      }}
      onSubmit={onSubmitInternal}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        dirty
      }) => (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            type="password"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="passphrase"
            label={t("components.EnterPassphraseForm.labels.passphrase")}
            name="passphrase"
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.passphrase}
            helperText={errors.passphrase && touched.passphrase && errors.passphrase}
            value={values.passphrase}
          />
          {secondaryButton ? (
            <Box display="flex" mt={6} justifyContent="center">
              {secondaryButton}
              <SubmitButton
                isSubmitting={isSubmitting}
                dirty={dirty}
                isLoading={isLoading}
                classes={classes}
                customButtonTitle={customButtonTitle}
              />
            </Box>
          ) : (
            <SubmitButton
              isSubmitting={isSubmitting}
              dirty={dirty}
              fullWidth
              isLoading={isLoading}
              classes={classes}
              customButtonTitle={customButtonTitle}
            />
          )}
        </form>
      )}
    </Formik>
  )
}

export default EnterPassphraseForm
