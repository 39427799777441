import {
  FETCH_BALANCES_FAILED,
  FETCH_BALANCES_START,
  FETCH_BALANCES_SUCCESS,
  SELL_ASSET_FAILED,
  SELL_ASSET_START,
  SELL_ASSET_SUCCESS,
  UPDATE_COINS_BALANCES
} from "../actions/wallet"

const initialState = {
  balances: [],
  gaspBalance: 0,
  aspBalance: 0,
  enerchiBalance: 0,
  loadingBalances: false,
  errorSellAsset: false,
  loadingSellAsset: false
}

const walletReducer = function (state = initialState, action) {
  switch (action.type) {
    case FETCH_BALANCES_START:
      return {
        ...state,
        loadingBalances: true
      }
    case FETCH_BALANCES_SUCCESS:
      return {
        ...state,
        balances: action.payload,
        loadingBalances: false
      }
    case FETCH_BALANCES_FAILED:
      return {
        ...state,
        loadingBalances: false
      }
    case UPDATE_COINS_BALANCES:
      return {
        ...state,
        gaspBalance: action.payload.gaspBalance,
        aspBalance: action.payload.aspBalance,
        enerchiBalance: action.payload.enerchiBalance
      }
    case SELL_ASSET_START:
      return {
        ...state,
        loadingSellAsset: true
      }
    case SELL_ASSET_SUCCESS:
      return {
        ...state,
        errorSellAsset: false,
        loadingSellAsset: false
      }
    case SELL_ASSET_FAILED:
      return {
        ...state,
        loadingSellAsset: false,
        errorSellAsset: true
      }
    default:
      return state
  }
}

export default walletReducer
