export const getFirstWalletAddress = (walletAddresses) => {
  if (!walletAddresses) {
    return null
  }
  if (walletAddresses.length === 0) {
    return null
  }
  const addresses = walletAddresses.map((address) => address.addressId)
  return addresses[0]
}

export const getOwnerFullName = (owner) => {
  return `${owner.firstName} ${owner.lastName}`
}

export const getOwnerIsBuyer = (owner, user) => {
  if (!owner || !user) return false
  return owner.id === user.id
}
