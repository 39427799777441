import { Api } from "../api"
import { ApiResponse } from "apisauce"

export default class ErrorTransform {
  error: boolean

  sync: boolean

  apiClient: Api

  constructor(api: Api) {
    this.error = false
    this.sync = false
    this.apiClient = api

    this.apiClient.apisauce.addMonitor((res: any) => this.onResponse(res))
  }

  async onResponse(response: ApiResponse<any>) {
    // Exit if is okay
    if (response.ok) {
      return
    }
    this.error = true
    if (response.status) {
      switch (response.status) {
        case 401:
          // showToastMessage("Su sesion ha caducado", "danger")
          break
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
        case 505:
          // showToastMessage("Ha surgido un error, intente nuevamente", "danger")
          break
        default:
          break
      }
    }
  }
}
