import LazyLoad from "react-lazy-load"
import { Tween, Timeline } from "react-gsap"
import LazyImage from "react-lazy-blur-image"
import { Skeleton } from "@material-ui/lab"

const DEFAULT_HEIGHT = 360

interface IpfsViewProps {
  imageStyle: any
  assetImageUrl: string
  onClickImage?: () => void
  rootContainerStyle?: any
  isLoading?: boolean
  skeletonHeight?: number
}

const IpfsView = (props: IpfsViewProps) => {
  const {
    imageStyle,
    assetImageUrl,
    onClickImage,
    rootContainerStyle,
    isLoading = false,
    skeletonHeight
  } = props

  if (isLoading || !assetImageUrl) {
    return (
      <div className={rootContainerStyle}>
        <Skeleton variant="rect" height={skeletonHeight || DEFAULT_HEIGHT} />
      </div>
    )
  }

  return (
    <LazyLoad debounce={false} className={rootContainerStyle} offsetVertical={500}>
      <Timeline
        target={
          <LazyImage
            uri={assetImageUrl}
            placeholder={assetImageUrl}
            render={(src, style) => (
              <img src={src} className={imageStyle} onClick={onClickImage} style={style} />
            )}
          />
        }
      >
        <Tween
          staggerFrom={{ opacity: 0, y: "20px" }}
          staggerTo={{ opacity: 1, y: "200px" }}
          stagger={0.5}
          duration={2}
          position="+=150"
        />
      </Timeline>
    </LazyLoad>
  )
}

export default IpfsView
