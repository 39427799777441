import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"
import { Popover, Typography } from "@material-ui/core"
import React from "react"
import ContentCopy from "@material-ui/icons/FileCopy"
import BasePaper from "../BasePaper"

interface CopyButtonProps {
  classes: any
  textToCopy: string
}
const CopyButton = ({ classes, textToCopy }: CopyButtonProps) => {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
    const textField = document.createElement("textarea")
    textField.innerText = textToCopy
    document.body.appendChild(textField)
    textField.select()
    document.execCommand("copy")
    textField.remove()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  return (
    <BasePaper className={classes.paperCopyButton}>
      <Typography variant="body1" color="primary" gutterBottom>
        {textToCopy}
      </Typography>
      <Button
        aria-describedby={id}
        variant="contained"
        className={classes.buttonCopy}
        color="primary"
        onClick={handleClick}
      >
        <ContentCopy />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
      >
        <Typography className={classes.typography}>{t("base.copied")}</Typography>
      </Popover>
    </BasePaper>
  )
}

export default CopyButton
