import React, { useState } from "react"
import Avatar from "@material-ui/core/Avatar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { resetPasswordStart } from "../../store/actions/auth"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Footer from "../../components/Footer"
import { useTranslation } from "react-i18next"
import IconButton from "@material-ui/core/IconButton"
import HomeIcon from "@material-ui/icons/Home"
import ResetPasswordForm, { ResetPasswordFormValues } from "../../components/ResetPasswordForm"
import { Chip } from "@material-ui/core"
import DoneIcon from "@material-ui/icons/Done"
import { GlobalState } from "../../store/reducers"
import BaseContainer from "../../components/BaseContainer"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  buttonGoBackPaper: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 1
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  iconsBar: {
    alignSelf: "flex-start",
    alignItems: "flex-start",
    flex: 1,
    display: "flex"
  },
  link: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  chipContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingBottom: theme.spacing(10),
    paddingTop: theme.spacing(10)
  }
}))

const ResetPassword = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history: any = useHistory()
  const [sended, setSended] = useState(false)
  const { isLoading } = useSelector((state: GlobalState) => state.authReducer)

  const goToHome = () => history.push("/")
  const goToSignIn = () => history.push("/login")
  const resetPassword = (email, callback) => dispatch(resetPasswordStart(email, callback))

  const onResetPasswordSubmit = ({ email }: ResetPasswordFormValues) => {
    resetPassword(email, () => setSended(true))
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <BaseContainer customClass={classes.content}>
        <Container component="main" maxWidth="xs" className={classes.container}>
          <div className={classes.paper}>
            <div className={classes.iconsBar}>
              <IconButton color="inherit" onClick={goToHome}>
                <HomeIcon />
              </IconButton>
            </div>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("screens.ResetPassword.title")}
            </Typography>
            {sended ? (
              <div className={classes.chipContainer}>
                <Chip
                  icon={<DoneIcon />}
                  label={t("screens.ResetPassword.sendedText")}
                  clickable
                  color="primary"
                  onClick={goToSignIn}
                />
              </div>
            ) : (
              <React.Fragment>
                <ResetPasswordForm
                  classes={classes}
                  isLoading={isLoading}
                  onSubmit={onResetPasswordSubmit}
                />
                <Grid container>
                  <Grid item xs>
                    <Link
                      onClick={goToSignIn}
                      variant="body2"
                      className={classes.link}
                      color="inherit"
                    >
                      {t("screens.ResetPassword.signInLink")}
                    </Link>
                  </Grid>
                  <Grid item />
                </Grid>
              </React.Fragment>
            )}
          </div>
        </Container>
        <Box mt={8}>
          <Footer />
        </Box>
      </BaseContainer>
    </div>
  )
}

export default ResetPassword
