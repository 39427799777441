import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from "@material-ui/core"
import Typography from "@material-ui/core/Typography"
import { useTranslation } from "react-i18next"
import { AssetExtraData, AssetModel } from "../../Models/asset"
import { PaymentMethodType } from "../../utils/types"
import EnterAssetPriceForm from "../EnterAssetPriceForm"
import { ASPIRE_COIN_ASSETS } from "../../utils/constants"
import { ReactElement, useEffect } from "react"
import { EnterAssetPriceFormValues } from "../EnterAssetPriceForm/EnterAssetPriceForm"

interface PaymentMethodStepProps {
  setPaymentMethod: (paymentMethod: PaymentMethodType) => void
  paymentMethod: PaymentMethodType
  assetExtraData: AssetExtraData
  asset: AssetModel
  secondaryButton: ReactElement<any, any>
  onSubmitPrice: (data: EnterAssetPriceFormValues) => void
  onFetchCoinValue: (paymentMethod, price) => void
  coinValue: number
}

export default function PaymentMethodStep({
  paymentMethod,
  setPaymentMethod,
  assetExtraData,
  asset,
  secondaryButton,
  onSubmitPrice,
  onFetchCoinValue,
  coinValue
}: PaymentMethodStepProps) {
  const { t } = useTranslation()

  const handleChange = (e) => {
    setPaymentMethod(e.target.value)
  }

  useEffect(() => {
    handleChange({ target: { value: ASPIRE_COIN_ASSETS.GASP } })
  }, [])

  const style: any = { margin: "auto" }
  return (
    <div style={style}>
      <Typography variant="h4" align="center" gutterBottom>
        {t("components.BuyStepper.PaymentMethodStep.title")}
      </Typography>
      <Typography variant="h6" align="center" gutterBottom>
        {assetExtraData.assetName}
      </Typography>
      <Box mt={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">
            {t("components.BuyStepper.PaymentMethodStep.choosePaymentMethod")}
          </FormLabel>
          <RadioGroup
            aria-label="Payment Method"
            name="paymentMethod"
            value={paymentMethod}
            onChange={handleChange}
          >
            {/*<FormControlLabel
              value={ASPIRE_COIN_ASSETS.ENERCHI}
              control={<Radio />}
              label={ASPIRE_COIN_ASSETS.ENERCHI}
            />*/}
            <FormControlLabel
              value={ASPIRE_COIN_ASSETS.GASP}
              control={<Radio />}
              label={ASPIRE_COIN_ASSETS.GASP}
            />
            <FormControlLabel
              value={ASPIRE_COIN_ASSETS.ASP}
              control={<Radio />}
              label={ASPIRE_COIN_ASSETS.ASP}
            />
            {/*<FormControlLabel
              value="1"
              disabled
              control={<Radio />}
              label="Credit Card XXXX-8048"
            />*/}
          </RadioGroup>
        </FormControl>
        <div>
          <FormControl component="fieldset">
            <FormLabel component="legend">
              {t("components.BuyStepper.PaymentMethodStep.offerPrice")}
            </FormLabel>
            <EnterAssetPriceForm
              classes={{}}
              defaultPrice={asset.price}
              isLoading={false}
              onSubmit={onSubmitPrice}
              secondaryButton={secondaryButton}
              customButtonTitle={t("base.next")}
              paymentMethod={paymentMethod}
              onFetchCoinValue={onFetchCoinValue}
              coinValue={coinValue}
            />
          </FormControl>
        </div>
      </Box>
    </div>
  )
}
