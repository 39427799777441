import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Trans, useTranslation } from "react-i18next"
import EnterPassphraseForm from "../EnterPassphraseForm"
import useConfirmDialogStyles from "./styles"
import Button from "@material-ui/core/Button"
import { EnterPassphraseFormValues } from "../EnterPassphraseForm/EnterPassphraseForm"

interface ConfirmDialogProps {
  visible: boolean
  title: string
  subtitle?: string
  okButtonText: string
  okButtonPress: (data: EnterPassphraseFormValues) => void
  cancelButtonText: string
  onClose: () => void
  walletAddress: string
}

const ConfirmDialog = ({
  visible,
  title,
  subtitle,
  okButtonText,
  okButtonPress,
  cancelButtonText,
  onClose,
  walletAddress
}: ConfirmDialogProps) => {
  const { t } = useTranslation()
  const classes = useConfirmDialogStyles()

  return (
    <Dialog
      open={visible}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Trans t={t}>{title}</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Trans t={t}>{subtitle}</Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions classes={classes}>
        <EnterPassphraseForm
          classes={classes}
          isLoading={false}
          onSubmit={okButtonPress}
          secondaryButton={
            <Button
              variant="contained"
              onClick={onClose}
              color="default"
              className={classes.submit}
            >
              {cancelButtonText}
            </Button>
          }
          customButtonTitle={okButtonText}
          walletAddress={walletAddress}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ConfirmDialog
