import { call, getContext, put, select, takeLatest } from "redux-saga/effects"
import {
  CONFIRM_BUY_ASSET_START,
  confirmBuyAssetFailed,
  confirmBuyAssetSuccess,
  FETCH_COIN_VALUE_START,
  FETCH_NOTIFICATIONS_START,
  fetchCoinValueSuccess,
  fetchNotificationsFailed,
  fetchNotificationsSuccess,
  REJECT_NOTIFICATION,
  REQUEST_BUY_ASSET_START,
  requestBuyAssetFailed,
  requestBuyAssetSuccess
} from "../actions/cart"
import { getFirstWalletAddress } from "../../utils/userHelper"
import crypt from "../../utils/cryptHelper"
import sb from "satoshi-bitcoin"
import {
  ASPIRE_COIN_ASSETS,
  BUY_ASSET_ERRORS,
  MARKET_ASPIRE_COIN_ASSETS,
  NEW_ASSET_ERRORS
} from "../../utils/constants"
import { showDialog } from "../actions/dialog"
import { NOTIFICATION_STATUS } from "../../Models/notification"

export function* watchCartStart() {
  yield takeLatest(REQUEST_BUY_ASSET_START, buyAssetStepOne)
  yield takeLatest(CONFIRM_BUY_ASSET_START, sellAsset)
  yield takeLatest(FETCH_NOTIFICATIONS_START, fetchNotifications)
  yield takeLatest(REJECT_NOTIFICATION, rejectNotificationApi)
  yield takeLatest(FETCH_COIN_VALUE_START, fetchCoinValueApi)
}

function* fetchNotifications() {
  try {
    const environment = yield getContext("env")
    const {
      data: { data: notifications }
    } = yield call(environment.api.cartService.fetchNotifications)
    yield put(fetchNotificationsSuccess(notifications))
  } catch {
    yield put(fetchNotificationsFailed())
  }
}

function* rejectNotificationApi({ payload }: ReturnType<any>) {
  try {
    const environment = yield getContext("env")
    const { notification, callback } = payload
    yield call(
      environment.api.cartService.updateNotification,
      notification.id,
      NOTIFICATION_STATUS.REJECTED
    )

    const {
      data: { data: notifications }
    } = yield call(environment.api.cartService.fetchNotifications)
    yield put(fetchNotificationsSuccess(notifications))
    if (callback) {
      callback()
    }
  } catch {
    const title = "screens.Notifications.errors.rejectNotificationErrorTitle"
    const subtitle = "screens.Notifications.errors.rejectNotificationErrorSubtitle"
    yield put(showDialog(title, subtitle, "base.ok"))
  }
}

function* buyAssetStepOne({ payload }: ReturnType<any>) {
  try {
    const environment = yield getContext("env")
    const { user } = yield select(({ authReducer }) => authReducer)
    const { selectedAsset } = yield select(({ assetsReducer }) => assetsReducer)
    const { coinValue } = yield select(({ cartReducer }) => cartReducer)
    const { walletAddresses, id: buyerUserId } = user
    const { id: salableAssetId, assetName } = selectedAsset
    const { data, callback } = payload
    const { assetOwnerAddress, assetOwnerUserId, paymentMethod, price, passphrase } = data
    const buyerAddress = getFirstWalletAddress(walletAddresses)
    const quantity = Number(sb.toSatoshi(coinValue)).toFixed(0)
    const createSendData = {
      source: buyerAddress,
      destination: assetOwnerAddress,
      asset: paymentMethod,
      quantity: Number(quantity)
    }
    const {
      data: { result: unsignedHex, error }
    } = yield call(environment.api.cartService.createSend, createSendData)
    const sensitiveData = {
      passphrase,
      unsignedHex
    }
    const stringData = JSON.stringify(sensitiveData)
    const encryptedData = crypt.encrypt(stringData)
    const createOrderData = {
      buyerId: buyerUserId,
      sellerId: assetOwnerUserId,
      assetName,
      encryptedData,
      price,
      paymentPrice: coinValue,
      paymentMethod,
      salableAssetId: salableAssetId
    }
    if (
      error &&
      (error.code === BUY_ASSET_ERRORS.INSUFFICIENT_FUNDS || NEW_ASSET_ERRORS.INSUFFICIENT_FUNDS)
    )
      throw new Error(BUY_ASSET_ERRORS.INSUFFICIENT_FUNDS)
    const {
      status,
      data: { errors }
    } = yield call(environment.api.cartService.createOrderRequest, createOrderData)
    if (status !== 201) throw new Error(errors.salableAssetId[0])

    if (callback) {
      callback()
    }
    yield put(requestBuyAssetSuccess())
  } catch (e) {
    let title = "screens.AssetDetail.errors.creationErrorTitle"
    let subtitle = "screens.AssetDetail.errors.creationErrorSubtitle"
    if (e.message === NEW_ASSET_ERRORS.INSUFFICIENT_FUNDS) {
      title = "screens.AssetDetail.errors.insufficientFundsErrorTitle"
      subtitle = "screens.AssetDetail.errors.insufficientFundsErrorSubtitle"
    } else if (e.message === BUY_ASSET_ERRORS.BALANCE_EXHAUSTED) {
      title = "screens.AssetDetail.errors.balanceExhaustedErrorTitle"
      subtitle = "screens.AssetDetail.errors.balanceExhaustedErrorSubtitle"
    }

    yield put(showDialog(title, subtitle, "base.ok"))
    yield put(requestBuyAssetFailed())
  }
}

function* sendNFT(createSendData, sellerPassphrase) {
  const environment = yield getContext("env")
  const {
    data: { result: sendNftUnsignedHex, error }
  } = yield call(environment.api.cartService.createSend, createSendData)
  if (error && error.code === BUY_ASSET_ERRORS.INSUFFICIENT_FUNDS)
    throw new Error(BUY_ASSET_ERRORS.INSUFFICIENT_FUNDS)

  const encryptedSellerPassphrase = crypt.encrypt(sellerPassphrase)
  const {
    data: { error: errorSign }
  } = yield call(
    environment.api.assetsService.signRawTransaction,
    sendNftUnsignedHex,
    encryptedSellerPassphrase,
    true
  )

  if (errorSign) throw new Error("Error in sign of send NFT")
}

function* sellAsset({ payload }: ReturnType<any>) {
  try {
    const environment = yield getContext("env")
    const { data, callback } = payload
    const {
      id: notificationId,
      encryptedData,
      sellerData,
      buyerData,
      assetName,
      passphrase: sellerPassphrase
    } = data

    // SEND NFT:
    const { walletAddress: sellerAddress } = sellerData
    const { walletAddress: buyerAddress } = buyerData
    const createSendData = {
      source: sellerAddress,
      destination: buyerAddress,
      asset: assetName,
      quantity: 1
    }
    yield call(sendNFT, createSendData, sellerPassphrase)

    // DECRYPT DATA OF BUYER PAYMENT
    const decryptedData = crypt.decrypt(encryptedData)
    const { unsignedHex, passphrase: buyerPassphrase } = JSON.parse(decryptedData)
    const encryptedBuyerPassphrase = crypt.encrypt(buyerPassphrase)
    // MAKE THE PAYMENT
    // TODO: If it fails, we need to return back the NFT sent to the owner wallet
    const { error } = yield call(
      environment.api.assetsService.signRawTransaction,
      unsignedHex,
      encryptedBuyerPassphrase,
      true
    )
    if (error) throw new Error("Error in sign of Payment")

    // SET NOTIFICATION STATUS CONFIRMED
    yield call(
      environment.api.cartService.updateNotification,
      notificationId,
      NOTIFICATION_STATUS.CONFIRMED
    )

    // FETCH UPDATED NOTIFICATIONS
    const {
      data: { data: notifications }
    } = yield call(environment.api.cartService.fetchNotifications)
    yield put(fetchNotificationsSuccess(notifications))

    if (callback) {
      callback()
    }
    yield put(confirmBuyAssetSuccess())
  } catch (e) {
    let title = "screens.AssetDetail.errors.creationErrorTitle"
    let subtitle = "screens.AssetDetail.errors.creationErrorSubtitle"
    if (e.message === NEW_ASSET_ERRORS.INSUFFICIENT_FUNDS) {
      title = "screens.AssetDetail.errors.insufficientFundsErrorTitle"
      subtitle = "screens.AssetDetail.errors.insufficientFundsErrorSubtitle"
    }
    yield put(showDialog(title, subtitle, "base.ok"))
    yield put(confirmBuyAssetFailed())
  }
}

function* fetchCoinValueApi({ payload }: ReturnType<any>) {
  try {
    const environment = yield getContext("env")
    const { coinId, price } = payload
    if (coinId === ASPIRE_COIN_ASSETS.ENERCHI) {
      yield put(fetchCoinValueSuccess(price))
      return
    }
    const realCoingeckoCoinId = MARKET_ASPIRE_COIN_ASSETS[coinId]
    const {
      data: { market_data: marketData }
    } = yield call(environment.api.cartService.fetchCoins, realCoingeckoCoinId)
    const {
      current_price: { usd }
    } = marketData
    yield put(fetchCoinValueSuccess(usd * price))
  } catch {
    yield put(fetchCoinValueSuccess(0))
  }
}
