import { Card, CardContent, CardHeader, Grid, Typography } from "@material-ui/core"
import useAssetData from "../../hooks/useAssetData"
import IpfsView from "../IpfsView/IpfsVIew"
import { useTranslation } from "react-i18next"
import SellAssetForm from "../SellAssetForm"
import { SellAssetFormValues } from "../SellAssetForm/SellAssetForm"
import VideoPlayerThumbnail from "../VideoPlayerThumbnail"
import React, { useState } from "react"
import { getIsVideoFromFileExtension } from "../../utils/constants"

interface AssetCardPreviewProps {
  classes: any
  asset: any
  onSubmitSell: (data: SellAssetFormValues) => void
  isLoadingAssetSell: boolean
}

export default function AssetCardPreview({
  classes,
  asset,
  onSubmitSell,
  isLoadingAssetSell
}: AssetCardPreviewProps) {
  const { t } = useTranslation()
  const { assetName, assetImageUrl, price } = useAssetData({
    assetDescription: asset?.description || null
  })
  const [isVideo, setIsVideo] = useState(false)

  React.useEffect(() => {
    if (assetImageUrl) {
      setIsVideo(getIsVideoFromFileExtension(assetImageUrl))
    }
  }, [assetImageUrl])

  return (
    <>
      <Grid item key={asset.asset} xs={12} sm={12} md={8}>
        <Typography component="h1" variant="h3" align="center">
          {t("screens.Wallet.sellAsset")}
        </Typography>
        <Card>
          {isVideo ? (
            <div className={classes.videoAvatarPreview}>
              <VideoPlayerThumbnail src={assetImageUrl} />
            </div>
          ) : (
            <IpfsView
              assetImageUrl={assetImageUrl}
              imageStyle={classes.mediaPreview}
              rootContainerStyle={classes.mediaContainer}
            />
          )}
          <CardHeader
            title={assetName}
            subheader={asset.asset}
            titleTypographyProps={{ align: "center" }}
            subheaderTypographyProps={{ align: "center" }}
            className={classes.cardHeader}
          />
          <CardContent>
            <div className={classes.cardPricing}>
              <Typography variant="body2">
                {t("components.AssetCardPreview.defaultPrice")} {price}
              </Typography>
            </div>
            <div className={classes.cardPricing}>
              {price && (
                <SellAssetForm
                  classes={classes}
                  isLoading={isLoadingAssetSell}
                  defaultPrice={price}
                  defaultQuantity={asset.quantity}
                  onSubmit={onSubmitSell}
                />
              )}
            </div>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}
