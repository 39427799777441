import { Formik } from "formik"
import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"
import SpinnerAdornment from "../SpinnerAdornment"
import TextField from "@material-ui/core/TextField"

export type SellAssetFormValues =
  | Record<string, any>
  | {
      price: string
      quantity: number
    }

interface SellAssetFormProps {
  classes: any
  onSubmit: (data: SellAssetFormValues) => void
  isLoading: boolean
  defaultPrice: number | string
  customButtonTitle?: string
  defaultQuantity: number
}

const SellAssetForm = ({
  classes,
  isLoading,
  onSubmit,
  defaultPrice,
  customButtonTitle,
  defaultQuantity
}: SellAssetFormProps) => {
  const { t } = useTranslation()
  const customOnChange: any = (event, setFieldValue) => {
    const split = event.target.value.toString().split(".")
    if (split && split?.length > 1) {
      if (split[1].length >= 8) {
        event.preventDefault()
        return
      }
    }
    setFieldValue("price", event.target.value)
  }

  return (
    <Formik
      initialValues={{ price: defaultPrice, quantity: defaultQuantity }}
      validate={(values) => {
        const errors: SellAssetFormValues = {}
        if (!values.price) {
          errors.price = t("components.SellAssetForm.errors.priceRequired")
        }
        if (!values.quantity) {
          errors.quantity = t("components.SellAssetForm.errors.quantityRequired")
        }
        return errors
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue
      }) => (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            type="number"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputProps={{ inputProps: { min: 0 } }}
            id="price"
            label={t("components.SellAssetForm.labels.price")}
            name="price"
            onChange={(e) => customOnChange(e, setFieldValue)}
            onBlur={handleBlur}
            error={!!errors.price}
            helperText={errors.price && touched.price && errors.price}
            value={values.price}
          />
          <TextField
            type="number"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            InputProps={{ inputProps: { min: 1, max: defaultQuantity } }}
            id="quantity"
            label={t("components.SellAssetForm.labels.quantity")}
            name="quantity"
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!errors.quantity}
            helperText={errors.quantity && touched.quantity && errors.quantity}
            value={values.quantity}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isSubmitting && isLoading}
          >
            {isSubmitting && isLoading ? (
              <SpinnerAdornment />
            ) : (
              customButtonTitle || t("components.SellAssetForm.buttonSubmit")
            )}
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default SellAssetForm
