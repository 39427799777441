import ReactPlayer from "react-player/lazy"
import PlayArrowIcon from "@material-ui/icons/PlayArrow"
import useVideoPlayerThumbnailStyles from "./styles"

interface VideoPlayerThumbnailProps {
  src: string
  onClick?: () => void
  playing?: boolean
  controls?: boolean
}

const VideoPlayerThumbnail = ({
  src,
  onClick,
  playing = false,
  controls = false
}: VideoPlayerThumbnailProps) => {
  const classes = useVideoPlayerThumbnailStyles()

  return (
    <div className={classes.wrapper}>
      <ReactPlayer
        playing={playing}
        loop
        width="100%"
        height="100%"
        controls={controls}
        muted
        url={src}
        onClick={onClick}
      />
      <PlayArrowIcon className={classes.icon} color="primary" />
    </div>
  )
}

export default VideoPlayerThumbnail
