export const REQUEST_BUY_ASSET_START = "BUY_ASSET_START"
export const REQUEST_BUY_ASSET_SUCCESS = "REQUEST_BUY_ASSET_SUCCESS"
export const REQUEST_BUY_ASSET_FAILED = "REQUEST_BUY_ASSET_FAILED"
export const CONFIRM_BUY_ASSET_START = "CONFIRM_BUY_ASSET_START"
export const CONFIRM_BUY_ASSET_SUCCESS = "CONFIRM_BUY_ASSET_SUCCESS"
export const CONFIRM_BUY_ASSET_FAILED = "CONFIRM_BUY_ASSET_FAILED"
export const FETCH_NOTIFICATIONS_START = "FETCH_NOTIFICATIONS_START"
export const FETCH_NOTIFICATIONS_FAILED = "FETCH_NOTIFICATIONS_FAILED"
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS"
export const REJECT_NOTIFICATION = "REJECT_NOTIFICATION"
export const FETCH_COIN_VALUE_START = "FETCH_COIN_VALUE_START"
export const FETCH_COIN_VALUE_SUCCESS = "FETCH_COIN_VALUE_SUCCESS"

export const requestBuyAssetStart = (data, callback) => ({
  type: REQUEST_BUY_ASSET_START,
  payload: {
    data,
    callback
  }
})

export const requestBuyAssetFailed = () => ({
  type: REQUEST_BUY_ASSET_SUCCESS
})

export const requestBuyAssetSuccess = () => ({
  type: REQUEST_BUY_ASSET_FAILED
})

export const confirmBuyAssetStart = (data, callback) => ({
  type: CONFIRM_BUY_ASSET_START,
  payload: {
    data,
    callback
  }
})

export const confirmBuyAssetFailed = () => ({
  type: CONFIRM_BUY_ASSET_SUCCESS
})

export const confirmBuyAssetSuccess = () => ({
  type: CONFIRM_BUY_ASSET_FAILED
})

export const fetchNotificationsStart = () => ({
  type: FETCH_NOTIFICATIONS_START
})

export const fetchNotificationsFailed = () => ({
  type: FETCH_NOTIFICATIONS_FAILED
})

export const fetchNotificationsSuccess = (notifications) => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload: notifications
})

export const rejectNotification = (notification, callback) => ({
  type: REJECT_NOTIFICATION,
  payload: {
    notification,
    callback
  }
})

export const fetchCoinValueStart = (coinId, price) => ({
  type: FETCH_COIN_VALUE_START,
  payload: {
    coinId,
    price
  }
})

export const fetchCoinValueSuccess = (coinValue) => ({
  type: FETCH_COIN_VALUE_SUCCESS,
  payload: coinValue
})
