import { withStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"

const styles: any = {
  root: {
    marginLeft: 5
  }
}

const SpinnerAdornment = withStyles(styles)((props) => <CircularProgress size={20} />)
export default SpinnerAdornment
