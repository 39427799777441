import { BaseApi } from "../base-api"
import { ASPIRE_API_METHODS, AUTH_CONFIG, getRpcData } from "../../utils/constants"
import CoinGecko from "coingecko-api"

export class CartService extends BaseApi {
  createSend = async (params) => {
    return await this.apisauce.post(
      this.config.api.paths.aspireApi,
      getRpcData(ASPIRE_API_METHODS.CREATE_SEND, params),
      {
        ...AUTH_CONFIG
      }
    )
  }

  fetchNotifications = async () => {
    return await this.apisauce.get(this.config.api.paths.orderRequests)
  }

  updateNotification = async (notificationId, status) => {
    return await this.apisauce.put(`${this.config.api.paths.orderRequests}/${notificationId}`, {
      status
    })
  }

  createOrderRequest = async (params) => {
    return await this.apisauce.post(this.config.api.paths.orderRequests, params)
  }

  fetchCoins = async (coinId: string) => {
    const CoinGeckoClient = new CoinGecko()
    return await CoinGeckoClient.coins.fetch(coinId)
  }
}
