import "./App.css"
import RootNavigator from "./navigators/root-navigator"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import configStore from "./store"
import { ThemeProvider } from "@material-ui/core"
import darkTheme from "./theme"
import AlertDialog from "./components/AlertDialog"
import Notifications from "react-notify-toast"
import firebase from "firebase"
import { firebaseConfig } from "./utils/firebaseConfig"

const { store, persistor } = configStore()
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

const App = () => {
  const options = { zIndex: 1200, top: "73px", right: "10px" }
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={darkTheme}>
            <RootNavigator />
            <Notifications options={options} />
            <AlertDialog />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </div>
  )
}

export default App
