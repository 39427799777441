import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const useNftCardCStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 345,
      borderWidth: 2,
      borderColor: "white",
      "&:hover": {
        cursor: "pointer",
        background: "rgba(0,0,0,.6)!important" as any
      },
      paddingBottom: theme.spacing(2)
    },
    cardHeader: {
      padding: "unset",
      display: "block",
      overflow: "hidden"
    },
    mediaContainer: {
      minHeight: 380
    },
    media: {
      width: "100%",
      padding: theme.spacing(2)
    },
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest
      })
    },
    likesCount: {
      justifyContent: "center",
      alignItems: "center",
      marginLeft: 10
    },
    expandOpen: {
      transform: "rotate(180deg)"
    }
  })
)

export default useNftCardCStyles
