import { call, put, select, takeLatest } from "redux-saga/effects"
import { showDialog } from "../actions/dialog"
import { getContext } from "redux-saga/effects"
import {
  FETCH_BALANCES_START,
  fetchBalancesFailed,
  fetchBalancesSuccess,
  REMOVE_SALABLE_ASSET_FROM_SALE,
  SELL_ASSET_START,
  sellAssetFailed,
  sellAssetSuccess,
  updateCoinsBalances
} from "../actions/wallet"
import { getFirstWalletAddress } from "../../utils/userHelper"
import { BUY_ASSET_ERRORS } from "../../utils/constants"
import {
  getAspBalancesAndRemoveFromAssetsInfo,
  getGaspBalance,
  mergeBalancesWithAssetInfo
} from "../../utils/assetsHelper"
import { SALABLE_ASSETS_STATUS } from "../../Models/asset"

export function* watchWalletStart() {
  yield takeLatest(FETCH_BALANCES_START, fetchWalletBalances)
  yield takeLatest(SELL_ASSET_START, sellAssetStart)
  yield takeLatest(REMOVE_SALABLE_ASSET_FROM_SALE, updateSalableAssetStatus)
}

function* fetchWalletBalances() {
  try {
    const { user } = yield select(({ authReducer }) => authReducer)
    const { id: userId, walletAddresses } = user
    const walletAddress = getFirstWalletAddress(walletAddresses)
    const environment = yield getContext("env")

    let assetsArray = []
    let assetsInfoArray = []

    // GET WALLET BALANCES
    const {
      data: { result: aspireAssets }
    } = yield call(environment.api.walletService.getWalletBalances, walletAddress)
    assetsInfoArray = aspireAssets

    // GET ASSETS INFO
    if (aspireAssets && aspireAssets.length > 0) {
      const assetsNames = aspireAssets.map(({ asset }) => asset)
      const {
        data: { result: assetsInfoArray }
      } = yield call(environment.api.assetsService.fetchAssetsInfo, assetsNames)
      assetsArray = assetsInfoArray
    }

    // GET MY SALABLE ASSETS AND MERGE WITH IS_SALABLE
    const {
      data: { data: mySalableAssets }
    } = yield call(environment.api.walletService.getMySalableAssets, userId)
    assetsInfoArray = mergeBalancesWithAssetInfo(assetsInfoArray, assetsArray, mySalableAssets)

    // GET BTC BALANCES (GASP)
    const {
      data: { result }
    } = yield call(environment.api.walletService.getBTCBalances, walletAddress)
    const { aspBalance, enerchiBalance, filteredAssetsInfoArray } =
      getAspBalancesAndRemoveFromAssetsInfo(assetsInfoArray)
    const gaspBalance = getGaspBalance(result)
    yield put(updateCoinsBalances(aspBalance, gaspBalance, enerchiBalance))
    yield put(fetchBalancesSuccess(filteredAssetsInfoArray))
  } catch {
    yield put(fetchBalancesFailed())
    yield put(
      showDialog(
        "screens.Wallet.errors.unknownErrorTitle",
        "screens.Wallet.errors.unknownBalances",
        "base.ok"
      )
    )
  }
}

function* sellAssetStart({ payload }: ReturnType<any>) {
  try {
    const environment = yield getContext("env")
    const { assetName, price, details, quantity, name, callback } = payload
    const params = {
      assetName,
      price,
      details,
      name,
      quantity
    }
    const { status } = yield call(environment.api.walletService.sellAsset, params)
    if (status !== 201) throw new Error(BUY_ASSET_ERRORS.INTERNAL_SERVER_ERROR)

    if (callback) {
      callback()
    }
    yield call(fetchWalletBalances)
    yield put(sellAssetSuccess())
  } catch (e) {
    yield put(sellAssetFailed())
    yield put(
      showDialog(
        "screens.Wallet.errors.sellingAssetErrorTitle",
        "screens.Wallet.errors.sellingAssetErrorSubtitle",
        "base.ok"
      )
    )
  }
}

function* updateSalableAssetStatus({ payload }: ReturnType<any>) {
  try {
    const environment = yield getContext("env")
    const { salableAssetId, callback } = payload
    yield call(
      environment.api.walletService.updateSalableAssetStatus,
      salableAssetId,
      SALABLE_ASSETS_STATUS.DRAFT
    )

    if (callback) {
      callback()
    }
    yield call(fetchWalletBalances)
  } catch {
    yield put(
      showDialog(
        "screens.Wallet.errors.sellingAssetErrorTitle",
        "screens.Wallet.errors.sellingAssetErrorSubtitle",
        "base.ok"
      )
    )
  }
}
