import { makeStyles } from "@material-ui/core/styles"

const useNftListStyles: any = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4)
  },
  paperContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  }
}))

export default useNftListStyles
