import { Formik } from "formik"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { useTranslation } from "react-i18next"
import SpinnerAdornment from "./SpinnerAdornment"

export type ResetPasswordFormValues =
  | Record<string, any>
  | {
      email: string
    }
interface ResetPasswordFormProps {
  classes: any
  onSubmit: (loginData: ResetPasswordFormValues) => void
  isLoading: boolean
}
const ResetPasswordForm = ({ classes, isLoading, onSubmit }: ResetPasswordFormProps) => {
  const { t } = useTranslation()
  return (
    <Formik
      initialValues={{ email: "" }}
      validate={(values) => {
        const errors: ResetPasswordFormValues = {}
        if (!values.email) {
          errors.email = t("screens.ResetPassword.errors.email")
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
          errors.email = "Invalid email address"
        }
        return errors
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        dirty
      }) => (
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label={t("screens.ResetPassword.labels.email")}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
            autoComplete="email"
          />
          {errors.email && touched.email && errors.email}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={(isSubmitting && isLoading) || !dirty}
          >
            {isSubmitting && isLoading ? (
              <SpinnerAdornment />
            ) : (
              t("screens.ResetPassword.buttonSubmit")
            )}
          </Button>
        </form>
      )}
    </Formik>
  )
}

export default ResetPasswordForm
