import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { useDispatch, useSelector } from "react-redux"
import { hideDialog } from "../store/actions/dialog"
import { Trans, useTranslation } from "react-i18next"
import { GlobalState } from "../store/reducers"

const AlertDialog = () => {
  const { visible, title, subtitle, okButtonText } = useSelector(
    (state: GlobalState) => state.dialogReducer
  )
  const dispatch = useDispatch()
  const closeModal = () => dispatch(hideDialog())
  const { t } = useTranslation()
  return (
    <Dialog
      open={visible}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Trans t={t}>{title}</Trans>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Trans t={t}>{subtitle}</Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal} color="primary" autoFocus>
          <Trans t={t}>{okButtonText}</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialog
