import { Resource } from "i18next"
import en from "./en.json"

const resources: Resource = {
  en: {
    translation: en
  }
}

export default resources
