import firebase from "firebase"
import { load, remove, save } from "../../utils/storage"

const HEADERS_KEY_PREFIX = "auth_headers"
const HEADERS_KEYS: Array<string> = ["Authorization"]

export class Auth {
  static async saveHeaders(headers: any) {
    const headersInternal: Record<string, any> = {}
    HEADERS_KEYS.forEach((headerKey) => {
      headersInternal[headerKey] = headers[headerKey]
    })
    return save(HEADERS_KEY_PREFIX, headersInternal).then(() => {
      return headersInternal
    })
  }

  static removeHeaders() {
    remove(HEADERS_KEY_PREFIX)
  }

  static async getHeaders() {
    const currentUser = firebase?.auth().currentUser
    const firebaseTokenId: string = await currentUser?.getIdToken(true)
    let headers = await load(HEADERS_KEY_PREFIX)

    if (firebaseTokenId) {
      headers = await this.saveHeaders({ Authorization: firebaseTokenId })
    }
    return headers || { Authorization: firebaseTokenId }
  }
}
