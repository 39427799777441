export const LOGIN_START = "LOGIN_START"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILED = "LOGIN_FAILED"
export const LOGOUT = "LOGOUT"
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
export const LOGOUT_FAILED = "LOGOUT_FAILED"
export const SIGN_UP_START = "SIGN_UP_START"
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS"
export const SIGN_UP_FAILED = "SIGN_UP_FAILED"
export const RESET_PASSWORD_START = "RESET_PASSWORD_START"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED"

export const loginStart = (email: string, password: string, callback: () => void) => ({
  type: LOGIN_START,
  payload: {
    email,
    password,
    callback
  }
})

export const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user
})

export const loginFailed = () => ({
  type: LOGIN_FAILED
})

export const logout = (callback) => ({
  type: LOGOUT,
  payload: {
    callback
  }
})

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
})

export const logoutFailed = () => ({
  type: LOGOUT_FAILED
})

export const signUpStart = (
  signUpData: Record<string, any>,
  passphrase: string,
  callback: () => void
) => ({
  type: SIGN_UP_START,
  payload: {
    ...signUpData,
    passphrase,
    callback
  }
})

export const signUpSuccess = (user) => ({
  type: SIGN_UP_SUCCESS,
  payload: user
})

export const signUpFailed = () => ({
  type: SIGN_UP_FAILED
})

export const resetPasswordStart = (email, callback) => ({
  type: RESET_PASSWORD_START,
  payload: {
    email,
    callback
  }
})

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS
})

export const resetPasswordFailed = () => ({
  type: RESET_PASSWORD_FAILED
})
