import { makeStyles } from "@material-ui/core/styles"

const useNotificationsListStyles: any = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "relative",
    overflow: "auto",
    maxHeight: 500
  },
  paper: {
    padding: theme.spacing(4)
  },
  paperContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  }
}))

export default useNotificationsListStyles
