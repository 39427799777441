import { getIpfsAssetJson } from "../utils/constants"
import { useEffect, useRef, useState } from "react"
import sb from "satoshi-bitcoin"

interface UseAssetDataProps {
  assetDescription: string
}

const useAssetData = ({ assetDescription }: UseAssetDataProps) => {
  const [assetName, setAssetName] = useState("")
  const [assetImageUrl, setAssetImageUrl] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [price, setPrice] = useState("")
  const unmounted = useRef(false)
  useEffect(() => {
    return () => {
      unmounted.current = true
    }
  }, [])

  useEffect(() => {
    if (assetDescription) {
      getIpfsAssetJson(assetDescription).then((data) => {
        if (!unmounted.current) {
          setAssetImageUrl(data.image)
          setAssetName(data.asset_name)
          setPrice(sb.toBitcoin(data.price))
        }
        setIsLoading(false)
      })
    }
  }, [assetDescription])

  return {
    assetName,
    assetImageUrl,
    price,
    isLoading
  }
}

export default useAssetData
