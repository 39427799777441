import Avatar from "@material-ui/core/Avatar"
import CssBaseline from "@material-ui/core/CssBaseline"
import Link from "@material-ui/core/Link"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import { loginStart } from "../../store/actions/auth"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import LoginForm, { LoginFormValues } from "../../components/LoginForm"
import Footer from "../../components/Footer"
import { useTranslation } from "react-i18next"
import IconButton from "@material-ui/core/IconButton"
import HomeIcon from "@material-ui/icons/Home"
import { GlobalState } from "../../store/reducers"
import BaseContainer from "../../components/BaseContainer"

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  buttonGoBackPaper: {
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flex: 1
  },
  link: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  iconsBar: {
    alignSelf: "flex-start",
    alignItems: "flex-start",
    flex: 1,
    display: "flex"
  },
  root: {
    display: "flex"
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  container: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}))

const Login = () => {
  const classes = useStyles()
  const { t } = useTranslation()
  const history: any = useHistory()
  const dispatch = useDispatch()
  const { isLoading } = useSelector((state: GlobalState) => state.authReducer)

  const goToHome = () => history.push("/")
  const goToSignUp = () => history.push("/signUp")
  const goToResetPassword = () => history.push("/reset")

  const onLoginSubmit = ({ email, password }: LoginFormValues) => {
    dispatch(loginStart(email, password, goToHome))
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <BaseContainer customClass={classes.content}>
        <Container maxWidth="xs" className={classes.container}>
          <div className={classes.paper}>
            <div className={classes.iconsBar}>
              <IconButton color="inherit" onClick={goToHome}>
                <HomeIcon />
              </IconButton>
            </div>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              {t("screens.Login.title")}
            </Typography>
            <LoginForm classes={classes} isLoading={isLoading} onSubmit={onLoginSubmit} />
            <Grid container>
              <Grid item xs>
                <Link
                  onClick={goToResetPassword}
                  className={classes.link}
                  variant="body2"
                  color="inherit"
                >
                  {t("screens.Login.forgotPasswordLink")}
                </Link>
              </Grid>
              <Grid item>
                <Link onClick={goToSignUp} className={classes.link} variant="body2" color="inherit">
                  {t("screens.Login.signUpLink")}
                </Link>
              </Grid>
            </Grid>
          </div>
        </Container>
        <Box mt={8}>
          <Footer />
        </Box>
      </BaseContainer>
    </div>
  )
}

export default Login
