import { all, fork } from "redux-saga/effects"
import { watchAssetsStart } from "./assets.sagas"
import { watchAuthStart } from "./auth.sagas"
import { watchWalletStart } from "./wallet.sagas"
import { watchCartStart } from "./cart.sagas"

export const rootSaga = function* root() {
  yield all([
    fork(watchAssetsStart),
    fork(watchAuthStart),
    fork(watchWalletStart),
    fork(watchCartStart)
  ])
}
