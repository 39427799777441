import { makeStyles } from "@material-ui/core/styles"
import { red } from "@material-ui/core/colors"

const useStyles: any = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4)
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  currencyBold: {
    fontWeight: "bold"
  },
  menuButton: {
    marginRight: 36
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "auto",
    padding: theme.spacing(2)
  },
  rootCard: {
    borderWidth: 2,
    borderColor: "white"
  },
  rootCurrencyCard: {
    borderWidth: 2,
    borderColor: "white",
    padding: 20
  },
  rootAssetDataCard: {
    borderWidth: 2,
    borderColor: "white",
    padding: 20,
    marginBottom: 40
  },
  media: {
    width: "70%",
    padding: theme.spacing(6)
  },
  root: {
    display: "flex"
  },
  ownerContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    justifyContent: "flex-start",
    alignItems: "center"
  },
  currencyContainer: {
    display: "flex",
    flexDirection: "row",
    padding: 10,
    justifyContent: "center",
    alignItems: "center"
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingRight: 10
  },
  avatar: {
    backgroundColor: red[500],
    marginRight: 10
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalPaper: {
    position: "absolute",
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  authModal: {
    flexDirection: "column",
    display: "flex",
    justifyContent: "center",
    flex: 1,
    alignItems: "center"
  },
  authModalAction: {
    marginTop: theme.spacing(5)
  },
  authModalTitle: {
    marginTop: theme.spacing(2)
  }
}))

export default useStyles
