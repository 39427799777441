export const SHOW_DIALOG = "SHOW_DIALOG"
export const HIDE_DIALOG = "HIDE_DIALOG"

export const showDialog = (title: string, subtitle: string, okButtonText: string) => ({
  type: SHOW_DIALOG,
  payload: {
    title,
    subtitle,
    okButtonText
  }
})

export const hideDialog = () => ({
  type: HIDE_DIALOG
})
