import { makeStyles } from "@material-ui/core/styles"
import { red } from "@material-ui/core/colors"

const useWalletStyles: any = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  container: {
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4)
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto"
  },
  root: {
    display: "flex"
  },
  avatar: {
    backgroundColor: red[500],
    marginRight: 10
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[700]
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2)
  },
  loadingContainer: {
    display: "flex",
    justifyContent: "center",
    flex: 1
  },
  contentGrid: {
    marginTop: theme.spacing(2)
  },
  addressGrid: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2)
  },
  paperCopyButton: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "row",
    alignItems: "center"
  },
  buttonCopy: {
    margin: theme.spacing(2)
  },
  typography: {
    padding: theme.spacing(1)
  },
  mediaContainer: {
    display: "flex",
    justifyContent: "center"
  },
  media: {
    width: "100%!important" as any,
    padding: theme.spacing(1)
  },
  mediaPreview: {
    width: "30%",
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(4)
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modalPaper: {
    position: "absolute",
    width: 800,
    justifyContent: "center",
    display: "flex",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  },
  videoAvatar: {
    width: "100%",
    margin: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1)
  },
  videoAvatarPreview: {
    width: "50%",
    margin: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1)
  }
}))

export default useWalletStyles
