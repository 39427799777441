import CssBaseline from "@material-ui/core/CssBaseline"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import AppBar from "../../components/AppBar"
import { CircularProgress, Typography } from "@material-ui/core"
import Footer from "../../components/Footer"
import { useTranslation } from "react-i18next"
import useNotificationsStyles from "./styles"
import NotificationsList from "../../components/NotificationsList"
import { NotificationModel } from "../../Models/notification"
import ConfirmDialog from "../../components/ConfirmDialog"
import React, { useState } from "react"
import { EnterPassphraseFormValues } from "../../components/EnterPassphraseForm/EnterPassphraseForm"
import {
  confirmBuyAssetStart,
  fetchNotificationsStart,
  rejectNotification
} from "../../store/actions/cart"
import { useDispatch, useSelector } from "react-redux"
import firebase from "firebase"
import { firebaseConfig } from "../../utils/firebaseConfig"
import { GlobalState } from "../../store/reducers"
import { notify } from "react-notify-toast"
import { getFirstWalletAddress } from "../../utils/userHelper"

const Notifications = () => {
  const classes: any = useNotificationsStyles()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false)
  const [currentNotification, setCurrentNotification] = useState(null)
  const [assetExtraData, setAssetExtraData] = useState(null)
  const { notifications, isLoadingNotifications } = useSelector(
    (state: GlobalState) => state.cartReducer
  )
  const { user } = useSelector((state: GlobalState) => state.authReducer)
  const { walletAddresses } = user
  const walletAddress = getFirstWalletAddress(walletAddresses)
  const fetchNotifications = () => dispatch(fetchNotificationsStart())

  const confirmDialogTitle = t("screens.Notifications.acceptDialog.title", {
    assetName: assetExtraData?.assetName || ""
  })

  const onAcceptNotification = (notification: NotificationModel, assetExtraData: any) => {
    setCurrentNotification(notification)
    setAssetExtraData(assetExtraData)
    setConfirmDialogVisible(true)
  }

  const onSuccess = () => {
    setConfirmDialogVisible(false)
    notify.show(t("screens.Notifications.sellSuccess"), "success")
  }

  const onRejected = () => {
    notify.show(t("screens.Notifications.rejectSuccess"), "error")
  }

  const onAcceptSubmit = (data: EnterPassphraseFormValues) => {
    const completeData = {
      ...data,
      ...currentNotification
    }
    dispatch(confirmBuyAssetStart(completeData, onSuccess))
  }

  const onRejectNotification = (notification: NotificationModel) => {
    dispatch(rejectNotification(notification, onRejected))
  }

  React.useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig)
    }
    fetchNotifications()
  }, [])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="md" className={classes.container}>
          <Typography component="h1" variant="h3">
            {t("screens.Notifications.title")}
          </Typography>
          <Grid container spacing={5} className={classes.contentGrid}>
            {isLoadingNotifications ? (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            ) : (
              <NotificationsList
                onAcceptNotification={onAcceptNotification}
                onRejectNotification={onRejectNotification}
                notifications={notifications}
              />
            )}
          </Grid>
        </Container>
        <Box pt={4}>
          <Footer />
        </Box>
      </main>
      <ConfirmDialog
        visible={confirmDialogVisible}
        title={confirmDialogTitle}
        subtitle={t("screens.Notifications.acceptDialog.subTitle")}
        okButtonText={t("screens.Notifications.acceptDialog.okButton")}
        cancelButtonText={t("screens.Notifications.acceptDialog.cancelButton")}
        okButtonPress={onAcceptSubmit}
        onClose={() => setConfirmDialogVisible(false)}
        walletAddress={walletAddress}
      />
    </div>
  )
}

export default Notifications
