import { makeStyles } from "@material-ui/core/styles"

const useBalancesListStyles: any = makeStyles((theme) => ({
  cardHeader: {
    backgroundColor:
      theme.palette.type === "light" ? theme.palette.grey[200] : theme.palette.grey[700]
  },
  cardPricing: {
    display: "flex",
    justifyContent: "center",
    alignItems: "baseline",
    marginBottom: theme.spacing(2)
  },
  media: {
    width: "60%",
    padding: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(4)
  },
  paperContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(6)
  },
  videoAvatar: {
    width: "50%",
    margin: "auto",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1)
  }
}))

export default useBalancesListStyles
