import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import useNewAssetStyles from "./styles"
import { useDispatch, useSelector } from "react-redux"
import { newAsset } from "../../store/actions/assets"
import NewAssetForm from "../../components/NewAssetForm"
import { useHistory } from "react-router-dom"
import { useState } from "react"
import { GlobalState } from "../../store/reducers"
import { Modal, Typography } from "@material-ui/core"
import EnterPassphraseForm from "../../components/EnterPassphraseForm"
import { EnterPassphraseFormValues } from "../../components/EnterPassphraseForm/EnterPassphraseForm"
import { NewAssetFormValues } from "../../components/NewAssetForm/NewAssetForm"
import { useTranslation } from "react-i18next"
import { showDialog } from "../../store/actions/dialog"
import { getFirstWalletAddress } from "../../utils/userHelper"

const NewAsset = () => {
  const classes: any = useNewAssetStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { loadingNewAsset } = useSelector((state: GlobalState) => state.assetsReducer)
  const { user } = useSelector((state: GlobalState) => state.authReducer)
  const { walletAddresses } = user
  const walletAddress = getFirstWalletAddress(walletAddresses)
  const history: any = useHistory()
  const [open, setOpen] = useState(false)
  const [newAssetData, setNewAssetData] = useState<NewAssetFormValues | undefined>(undefined)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const goToWallet = () => {
    history.push("/wallet")
  }

  const onSubmit = (data: NewAssetFormValues) => {
    setNewAssetData(data)
    handleOpen()
  }

  const onSubmitPassphraseForm = (data: EnterPassphraseFormValues) => {
    const { passphrase } = data
    dispatch(newAsset(newAssetData, passphrase, goToWallet, handleClose))
  }

  const showErrorModal = (title, subtitle) => {
    dispatch(showDialog(title, subtitle, "base.ok"))
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="md" className={classes.container}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <NewAssetForm onError={showErrorModal} classes={classes} onSubmit={onSubmit} />
            </Grid>
          </Grid>
        </Container>
        <Box pt={4}>
          <Footer />
        </Box>
      </main>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalPaper}>
          <Typography component="h1" variant="h5" align="center">
            {t("components.EnterPassphraseForm.title")}
          </Typography>
          <EnterPassphraseForm
            classes={classes}
            isLoading={loadingNewAsset}
            onSubmit={onSubmitPassphraseForm}
            walletAddress={walletAddress}
          />
        </div>
      </Modal>
    </div>
  )
}

export default NewAsset
