import { Api } from "./api"
import { DEFAULT_API_CONFIG } from "./api-config"

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  constructor() {
    // create each service
    this.api = new Api(DEFAULT_API_CONFIG)
    this.api.setup()
  }

  /**
   * Our api.
   */
  api: Api
}

export function setupEnvironment() {
  const env: Environment = new Environment()
  return env
}
