import axios from "axios"
import humps from "humps"
import { create } from "apisauce"
import { ApiConfig } from "./api-config"
import { BaseApi } from "./base-api"

// Transforms
import AuthTransform from "./transforms/auth-transform"
import ErrorTransform from "./transforms/error-transform"

// Services
import { AuthService } from "./services/auth.service"
import { AssetsService } from "./services/assets.service"
import { WalletService } from "./services/wallet.service"
import { CartService } from "./services/cart.service"

/**
 * Manages all requests to the API.
 */
export class Api extends BaseApi {
  transforms: any[]

  authService: AuthService

  assetsService: AssetsService

  walletService: WalletService

  cartService: CartService

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   */
  constructor(config: ApiConfig) {
    super(config)
    this.transforms = []
  }

  /**
   * Sets up the API.  This will be called during the bootup
   * sequence and will happen before the first React component
   * is mounted.
   *
   * Be as quick as possible in here.
   */
  setup() {
    // construct the apisauce instance
    let { transformRequest, transformResponse }: any = axios.defaults

    transformRequest = [humps.decamelizeKeys, ...transformRequest]

    transformResponse = [...transformResponse, humps.camelizeKeys]
    // TODO: Check how we can handle that
    this.apisauce = create({
      baseURL: this.config.api.url,
      headers: this.config.api.headers,
      timeout: this.config.api.timeout,
      transformRequest,
      transformResponse
    })
    this.transforms.push(new AuthTransform(this))
    this.transforms.push(new ErrorTransform(this))

    this.authService = new AuthService(this.config, this.apisauce)
    this.assetsService = new AssetsService(this.config, this.apisauce)
    this.walletService = new WalletService(this.config, this.apisauce)
    this.cartService = new CartService(this.config, this.apisauce)
  }
}
