import { useDispatch, useSelector } from "react-redux"
import { fetchAssetsStart, searchAssetsStart, selectAsset } from "../../store/actions/assets"
import useHomeStyles from "./styles"
import { useHistory } from "react-router-dom"
import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import NftList from "../../components/NftList"
import Box from "@material-ui/core/Box"
import AppBar from "../../components/AppBar"
import Footer from "../../components/Footer"
import { GlobalState } from "../../store/reducers"
import { AssetModel } from "../../Models/asset"
import { fetchNotificationsStart } from "../../store/actions/cart"
import SearchBar from "../../components/SearchBar"
import { SearchBarValues } from "../../components/SearchBar/SearchBar"
import { Pagination } from "@material-ui/lab"
import BaseContainer from "../../components/BaseContainer"

const DEFAULT_PAGE_NUMBER = 1

const Home = () => {
  const classes: any = useHomeStyles()
  const dispatch = useDispatch()
  const { assets, loadingSearchAssets, metadata } = useSelector(
    (state: GlobalState) => state.assetsReducer
  )
  const { user } = useSelector((state: GlobalState) => state.authReducer)
  const fetchAssets = (page, search) => dispatch(fetchAssetsStart(page, search))
  const onSelectAsset = (asset: AssetModel) => dispatch(selectAsset(asset))
  const fetchNotifications = () => dispatch(fetchNotificationsStart())
  const searchAssets = (search, page) => dispatch(searchAssetsStart(search, page))
  const [page, setPage] = React.useState(DEFAULT_PAGE_NUMBER)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }
  const history = useHistory()

  const onCardPress = (asset: any) => {
    onSelectAsset(asset)
    history.push("/assetDetail")
  }

  const onSubmitSearch = (data: SearchBarValues) => {
    searchAssets(data.search, DEFAULT_PAGE_NUMBER)
  }

  React.useEffect(() => {
    if (user) {
      fetchNotifications()
    }
  }, [user])

  React.useEffect(() => {
    fetchAssets(page, "")
  }, [page])

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar />
      <BaseContainer customClass={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Container maxWidth="md" className={classes.container}>
            <SearchBar classes={classes} onSubmit={onSubmitSearch} isLoading={false} />
          </Container>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <NftList
                assets={assets}
                isLoadingSearch={loadingSearchAssets}
                onCardPress={onCardPress}
              />
              <Pagination
                variant="outlined"
                color="standard"
                shape="rounded"
                classes={{ root: classes.rootPagination, ul: classes.ulPagination }}
                defaultPage={DEFAULT_PAGE_NUMBER}
                count={metadata?.totalPages || DEFAULT_PAGE_NUMBER}
                page={page}
                onChange={handleChangePage}
              />
            </Grid>
          </Grid>
        </Container>
        <Box pt={4}>
          <Footer />
        </Box>
      </BaseContainer>
    </div>
  )
}

export default Home
