import { call, getContext, put, takeLatest, select } from "redux-saga/effects"
import {
  cleanAssets,
  FETCH_ASSETS_START,
  fetchAssetsFailed,
  fetchAssetsSuccess,
  NEW_ASSET,
  newAssetFailed,
  newAssetSuccess,
  SEARCH_ASSETS_START,
  searchAssetsFailed,
  searchAssetsSuccess,
  updateMetadata
} from "../actions/assets"
import { generateAssetName, getFormDataNewAsset } from "../../utils/assetsHelper"
import { showDialog } from "../actions/dialog"
import { getFirstWalletAddress } from "../../utils/userHelper"
import crypt from "../../utils/cryptHelper"
import { NEW_ASSET_ERROR_CODES_ASPIRE, NEW_ASSET_ERRORS } from "../../utils/constants"

export function* watchAssetsStart() {
  yield takeLatest(FETCH_ASSETS_START, fetchAssetsApi)
  yield takeLatest(NEW_ASSET, newAsset)
  yield takeLatest(SEARCH_ASSETS_START, searchAssets)
}

function* fetchAssetsApi({ payload }: ReturnType<any>) {
  try {
    const { page, search } = payload
    yield put(cleanAssets())

    const environment = yield getContext("env")
    const {
      data: { data: allAssets, meta: metadata }
    } = yield call(environment.api.assetsService.fetchAllAssets, page, search)
    yield put(updateMetadata(metadata))

    if (allAssets) {
      yield put(fetchAssetsSuccess(allAssets))
    }
  } catch {
    yield put(fetchAssetsFailed())
  }
}

function* newAsset({ payload }: ReturnType<any>) {
  try {
    const environment = yield getContext("env")
    // Get wallet address
    const { user } = yield select(({ authReducer }) => authReducer)
    const { walletAddresses } = user
    const walletAddress = getFirstWalletAddress(walletAddresses)
    // Generate asset name
    let assetName = generateAssetName()
    // Prepare data
    const { data, passphrase, callback } = payload
    const passphraseEncrypted = crypt.encrypt(passphrase)
    const {
      data: { result }
    } = yield call(environment.api.assetsService.verifyAssetName, assetName)
    assetName = result.length > 0 ? generateAssetName() : assetName
    const formData = getFormDataNewAsset(data, assetName)
    const {
      data: { IpfsHash: pinnedJsonIpfs }
    } = yield call(environment.api.assetsService.uploadAndPinData, formData)
    const assetData = {
      quantity: data.quantity,
      description: pinnedJsonIpfs,
      asset: assetName,
      source: walletAddress,
      divisible: false
    }
    const {
      data: { result: unsignedHex, error }
    } = yield call(environment.api.assetsService.createIssuance, assetData)
    if (error && error.code === NEW_ASSET_ERROR_CODES_ASPIRE.INSUFFICIENT_FUNDS)
      throw new Error(NEW_ASSET_ERRORS.INSUFFICIENT_FUNDS)
    yield call(
      environment.api.assetsService.signRawTransaction,
      unsignedHex,
      passphraseEncrypted,
      true
    )
    yield put(newAssetSuccess())
    yield put(
      showDialog(
        "screens.NewAsset.creationSuccessTitle",
        "screens.NewAsset.creationSuccessSubtitle",
        "base.ok"
      )
    )
    if (callback) {
      callback()
    }
  } catch (e) {
    const { callbackError } = payload
    let title = "screens.NewAsset.errors.creationErrorTitle"
    let subtitle = "screens.NewAsset.errors.creationErrorSubtitle"
    if (e.message === NEW_ASSET_ERRORS.INSUFFICIENT_FUNDS) {
      title = "screens.NewAsset.errors.insufficientFundsErrorTitle"
      subtitle = "screens.NewAsset.errors.insufficientFundsErrorSubtitle"
    }
    yield put(newAssetFailed())
    if (callbackError) {
      callbackError()
    }
    yield put(showDialog(title, subtitle, "base.ok"))
  }
}

function* searchAssets({ payload }: ReturnType<any>) {
  try {
    yield put(cleanAssets())
    const { page, search } = payload
    const environment = yield getContext("env")
    const {
      data: { data: assets, meta: metadata }
    } = yield call(environment.api.assetsService.fetchAllAssets, page, search)
    if (assets) {
      yield put(searchAssetsSuccess(assets))
    }
    yield put(updateMetadata(metadata))
  } catch (e) {
    yield put(searchAssetsFailed())
  }
}
