import { StepIconProps } from "@material-ui/core/StepIcon"
import clsx from "clsx"
import Check from "@material-ui/icons/Check"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import { Box, Button, Container } from "@material-ui/core"
import React from "react"
import PaymentMethodStep from "./PaymentMethodStep"
import ResumeStep from "./ResumeStep"
import SuccessStep from "./SuccessStep"
import { useTranslation } from "react-i18next"
import { useQontoStepIconStyles, useStyles } from "./styles"
import { QontoConnector } from "./QontoConnector"
import { AssetExtraData, AssetModel } from "../../Models/asset"
import { PaymentMethodType } from "../../utils/types"
import { PAYMENT_METHODS_ENUM } from "../../utils/constants"
import EnterPassphraseForm from "../EnterPassphraseForm"
import { EnterPassphraseFormValues } from "../EnterPassphraseForm/EnterPassphraseForm"
import { EnterAssetPriceFormValues } from "../EnterAssetPriceForm/EnterAssetPriceForm"

function QontoStepIcon(props: StepIconProps) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  )
}

function getSteps() {
  return ["components.BuyStepper.steps.paymentMethod", "components.BuyStepper.steps.resume"]
}

interface BuyStepperProps {
  handleClose: () => void
  asset: AssetModel
  assetExtraData: AssetExtraData
  onSubmitBuy: (data, callback) => void
  isLoadingRequestBuy: boolean
  walletAddress: string
  onFetchCoinValue: (paymentMethod, price) => void
  coinValue: number
}

export default function BuyStepper({
  handleClose,
  asset,
  assetExtraData,
  onSubmitBuy,
  isLoadingRequestBuy,
  walletAddress,
  onFetchCoinValue,
  coinValue
}: BuyStepperProps) {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const [paymentMethod, setPaymentMethod] = React.useState<PaymentMethodType>(
    PAYMENT_METHODS_ENUM.ENERCHI as PaymentMethodType
  )
  const [price, setPrice] = React.useState<string>(asset?.price || "0")
  const steps = getSteps()
  const { t } = useTranslation()

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const onSubmitPrice = ({ price }: EnterAssetPriceFormValues) => {
    setPrice(price)
    handleNext()
  }

  const onSubmitPassphrase = (data: EnterPassphraseFormValues) => {
    const { passphrase } = data
    const { asset: assetName, user } = asset
    const { id: assetOwnerUserId, addressId: assetOwnerAddress } = user
    const newData = {
      passphrase,
      paymentMethod,
      assetName,
      assetOwnerUserId,
      assetOwnerAddress,
      price
    }
    onSubmitBuy(newData, handleNext)
  }

  return (
    <div>
      <Container className={classes.root} maxWidth="md">
        {activeStep === steps.length ? (
          <SuccessStep onFinish={handleClose} asset={asset} />
        ) : (
          <div>
            <Box display="flex" mt={6} justifyContent="center">
              <div>
                {activeStep === 0 && (
                  <PaymentMethodStep
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                    assetExtraData={assetExtraData}
                    asset={asset}
                    onSubmitPrice={onSubmitPrice}
                    secondaryButton={
                      <Button disabled onClick={handleBack} className={classes.button}>
                        {t("base.back")}
                      </Button>
                    }
                    onFetchCoinValue={onFetchCoinValue}
                    coinValue={coinValue}
                  />
                )}
                {activeStep === 1 && (
                  <ResumeStep
                    paymentMethod={paymentMethod}
                    asset={asset}
                    price={price}
                    assetExtraData={assetExtraData}
                  />
                )}
                {activeStep === steps.length - 1 && (
                  <EnterPassphraseForm
                    classes={classes}
                    isLoading={isLoadingRequestBuy}
                    onSubmit={onSubmitPassphrase}
                    secondaryButton={
                      <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        className={classes.button}
                      >
                        {t("base.back")}
                      </Button>
                    }
                    customButtonTitle={t("base.next")}
                    walletAddress={walletAddress}
                  />
                )}
              </div>
            </Box>
          </div>
        )}
      </Container>
      <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{t(label)}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  )
}
