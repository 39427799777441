import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export const useBaseContainerClasses = makeStyles((theme: Theme) =>
  createStyles({
    rootScene: {
      width: "100%",
      backgroundImage: "linear-gradient(180deg, #450063 33%, #00ffe1 100%)"
    }
  })
)
