import React, { useState } from "react"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Box,
  Container
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { AssetExtraData, AssetModel } from "../../Models/asset"
import { PaymentMethodType } from "../../utils/types"
import { getOwnerFullName } from "../../utils/userHelper"
import VideoPlayerThumbnail from "../VideoPlayerThumbnail"
import { getIsVideoFromFileExtension } from "../../utils/constants"

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
    width: "100%"
  },
  total: {
    fontWeight: 700
  },
  title: {
    marginTop: theme.spacing(2)
  },
  videoAvatar: {
    width: 70,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: theme.spacing(2)
  }
}))

interface ResumeStepProps {
  asset: AssetModel
  paymentMethod: PaymentMethodType
  assetExtraData: AssetExtraData
  price: string
}

const ResumeStep = ({ asset, paymentMethod, assetExtraData, price }: ResumeStepProps) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const ownerFullName = asset.user ? getOwnerFullName(asset.user) : ""
  const quantity = 1
  const [isVideo, setIsVideo] = useState(false)

  React.useEffect(() => {
    if (assetExtraData) {
      setIsVideo(getIsVideoFromFileExtension(assetExtraData.assetImageUrl))
    }
  }, [assetExtraData])

  return (
    <Box display="flex" width={500}>
      <Container maxWidth="xl">
        <Typography variant="h4" align="center" gutterBottom>
          {t("components.BuyStepper.ResumeStep.title")}
        </Typography>
        <List disablePadding>
          <ListItem className={classes.listItem} key={ownerFullName}>
            <ListItemAvatar>
              {isVideo ? (
                <Avatar>
                  <VideoPlayerThumbnail src={assetExtraData.assetImageUrl} />
                </Avatar>
              ) : (
                <Avatar src={assetExtraData.assetImageUrl} />
              )}
            </ListItemAvatar>
            <ListItemText primary={assetExtraData.assetName} secondary={ownerFullName} />
            <Typography variant="body2">{quantity}</Typography>
          </ListItem>
          <ListItem className={classes.listItem}>
            <ListItemText primary={t("components.BuyStepper.ResumeStep.total")} />
            <Typography variant="subtitle1" className={classes.total}>
              {price}
            </Typography>
          </ListItem>
        </List>
        <Grid item container direction="row" alignItems="center">
          <Typography variant="h6" gutterBottom className={classes.title}>
            {t("components.BuyStepper.ResumeStep.paymentDetailsTitle")}
          </Typography>
          <Grid container>
            <React.Fragment>
              <Grid item xs={6}>
                <Typography gutterBottom>{t("base.asset")}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align="right" gutterBottom>
                  {paymentMethod}
                </Typography>
              </Grid>
            </React.Fragment>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ResumeStep
