import { ApisauceInstance } from "apisauce"
import { ApiConfig, DEFAULT_API_CONFIG } from "./api-config"

export abstract class BaseApi {
  /**
   * The underlying apisauce instance which performs the requests.
   */
  apisauce: ApisauceInstance

  /**
   * Configurable options.
   */
  config: ApiConfig

  /**
   * Creates the api.
   *
   * @param config The configuration to use.
   * @param apiSauce
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG, apiSauce: ApisauceInstance = null) {
    this.config = config
    this.apisauce = apiSauce
  }
}
