const PROFILE = "/profile"
const WALLET_USERS = "/wallet_users"
const ASPIRE_API = process.env.REACT_APP_ASPIRE_API
const NODE_API_BASE_URL = process.env.REACT_APP_NODE_API_BASE
const NODE_SIGN_RAW_TRANSACTION = NODE_API_BASE_URL + "/signRawTransaction"
const NODE_GET_BALANCES = NODE_API_BASE_URL + "/getBalances"
const NODE_UPLOAD_AND_PIN_DATA = NODE_API_BASE_URL + "/uploadAndPinData"
const ORDER_REQUESTS = "/order_requests"
const SALABLE_ASSETS = "/salable_assets"

const paths = {
  PROFILE,
  WALLET_USERS,
  ASPIRE_API,
  NODE_GET_BALANCES,
  NODE_SIGN_RAW_TRANSACTION,
  NODE_UPLOAD_AND_PIN_DATA,
  ORDER_REQUESTS,
  SALABLE_ASSETS
}

export default paths
