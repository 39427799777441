import BalanceCard from "../BalanceCard"
import { BalanceModel } from "../../Models/balance"
import { Container, Paper, Typography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import useBalancesListStyles from "./styles"

interface BalancesListProps {
  balances: Array<BalanceModel>
  onSellPress: (coinBalance: BalanceModel) => void
  onRemoveSalePress: (salableAssetId) => void
}

const BalancesList = (props: BalancesListProps) => {
  const classes: any = useBalancesListStyles()
  const { balances, onSellPress, onRemoveSalePress } = props
  const { t } = useTranslation()

  if (!balances || balances.length === 0) {
    return (
      <div className={classes.paperContainer}>
        <Paper className={classes.paper}>
          <Container>
            <Typography variant="h5">{t("screens.Wallet.noBalances")}</Typography>
          </Container>
        </Paper>
      </div>
    )
  }

  return (
    <>
      {balances.map(
        (coinBalance: BalanceModel) =>
          coinBalance && (
            <BalanceCard
              key={coinBalance.asset}
              classes={classes}
              coinBalance={coinBalance}
              onSellPress={onSellPress}
              onRemoveSalePress={onRemoveSalePress}
              skeletonHeight={170}
            />
          )
      )}
    </>
  )
}

export default BalancesList
