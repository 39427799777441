import ReactPlayer from "react-player/lazy"

interface VideoPlayerProps {
  src: string
  onClick?: () => void
  playing?: boolean
  controls?: boolean
}

const VideoPlayer = ({ src, onClick, playing = true, controls = true }: VideoPlayerProps) => {
  return (
    <ReactPlayer
      playing={playing}
      loop
      width="100%"
      controls={controls}
      muted
      url={src}
      onClick={onClick}
    />
  )
}

export default VideoPlayer
