import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export const useBaseCardClasses = makeStyles((theme: Theme) =>
  createStyles({
    rootCard: {
      backgroundColor: "rgba(0,0,0,.3)!important" as any,
      color: "#ffffff"
    }
  })
)
