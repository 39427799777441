import {
  CLEAN_ASSETS,
  CLEAR_ASSET_SELECTED,
  FETCH_ASSETS_FAILED,
  FETCH_ASSETS_START,
  FETCH_ASSETS_SUCCESS,
  NEW_ASSET,
  NEW_ASSET_FAILED,
  NEW_ASSET_SUCCESS,
  SEARCH_ASSETS_FAILED,
  SEARCH_ASSETS_START,
  SEARCH_ASSETS_SUCCESS,
  SELECT_ASSET,
  UPDATE_METADATA
} from "../actions/assets"

const initialState = {
  assets: [],
  selectedAsset: null,
  loadingNewAsset: false,
  loadingSearchAssets: false,
  metadata: {}
}

const assetsReducer = function (state = initialState, action) {
  switch (action.type) {
    case FETCH_ASSETS_START:
      return {
        ...state,
        loadingSearchAssets: true
      }
    case FETCH_ASSETS_SUCCESS:
      const newVar = {
        ...state,
        assets: action.payload,
        loadingSearchAssets: false
      }
      return newVar
    case FETCH_ASSETS_FAILED:
      return {
        ...state,
        loadingSearchAssets: false
      }
    case SELECT_ASSET:
      return {
        ...state,
        selectedAsset: action.payload
      }
    case CLEAR_ASSET_SELECTED:
      return {
        ...state,
        selectedAsset: null
      }
    case NEW_ASSET:
      return {
        ...state,
        loadingNewAsset: true
      }
    case NEW_ASSET_SUCCESS:
      return {
        ...state,
        loadingNewAsset: false
      }
    case NEW_ASSET_FAILED:
      return {
        ...state,
        loadingNewAsset: false
      }
    case SEARCH_ASSETS_START:
      return {
        ...state,
        loadingSearchAssets: true
      }
    case SEARCH_ASSETS_SUCCESS:
      return {
        ...state,
        loadingSearchAssets: false,
        assets: action.payload
      }
    case SEARCH_ASSETS_FAILED:
      return {
        ...state,
        loadingSearchAssets: false
      }
    case CLEAN_ASSETS:
      return {
        ...state,
        assets: []
      }
    case UPDATE_METADATA:
      return {
        ...state,
        metadata: action.payload
      }
    default:
      return state
  }
}

export default assetsReducer
