import React, { useState } from "react"
import CardHeader from "@material-ui/core/CardHeader"
import Typography from "@material-ui/core/Typography"
import { Grid } from "@material-ui/core"
import IpfsView from "../IpfsView/IpfsVIew"
import useAssetData from "../../hooks/useAssetData"
import { getOwnerFullName } from "../../utils/userHelper"
import useNftCardCStyles from "./styles"
import { getIsVideoFromFileExtension } from "../../utils/constants"
import VideoPlayer from "../VideoPlayer"
import BaseCard from "../BaseCard"

interface NftCardProps {
  asset: Record<string, any>
  onCardPress: (asset: any) => void
}

export default function NftCard({ asset, onCardPress }: NftCardProps) {
  const classes = useNftCardCStyles()
  const [shadow, setShadow] = React.useState(false)
  const { assetName, assetImageUrl, isLoading } = useAssetData({ assetDescription: asset.details })
  const [isVideo, setIsVideo] = useState(false)
  const ownerFullName = asset.user ? getOwnerFullName(asset.user) : ""

  const onMouseOver = () => setShadow(true)
  const onMouseOut = () => setShadow(false)
  const handleCardPress = () => {
    onCardPress(asset)
  }

  React.useEffect(() => {
    if (assetImageUrl) {
      setIsVideo(getIsVideoFromFileExtension(assetImageUrl))
    }
  }, [assetImageUrl])

  return (
    <BaseCard
      className={classes.root}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      raised={shadow}
    >
      <div className={classes.mediaContainer}>
        {isVideo ? (
          <VideoPlayer src={assetImageUrl} onClick={handleCardPress} />
        ) : (
          <IpfsView
            onClickImage={handleCardPress}
            assetImageUrl={assetImageUrl}
            imageStyle={classes.media}
            isLoading={isLoading}
          />
        )}
      </div>
      <div onClick={handleCardPress}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item xs={8}>
                <Typography variant="body2" color="textSecondary" noWrap align="left" component="p">
                  {ownerFullName}
                </Typography>
                <CardHeader
                  className={classes.cardHeader}
                  title={
                    <Typography noWrap variant="h6" align="left" component="h4">
                      {assetName}
                    </Typography>
                  }
                />
              </Grid>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary" component="p">
                  {parseInt("" + asset.price * 100) / 100} USD
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </BaseCard>
  )
}
