import { AxiosRequestConfig } from "axios"
import { Api } from "../api"
import { Auth } from "../auth"

export default class AuthTransform {
  isLogin: boolean

  isLogOut: boolean

  isExternal: boolean

  sync: boolean

  apiClient: Api

  constructor(api: Api) {
    this.isLogin = false
    this.isLogOut = false
    this.sync = false
    this.apiClient = api

    this.apiClient.apisauce.addAsyncRequestTransform((config: any) => this.onRequest(config))
  }

  async onRequest(request: AxiosRequestConfig) {
    const authHeaders: any = await Auth.getHeaders()
    for (const key in authHeaders) {
      request.headers[key] = authHeaders[key]
    }
  }
}
