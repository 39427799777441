import {
  LOGIN_FAILED,
  LOGIN_START,
  LOGIN_SUCCESS,
  LOGOUT_FAILED,
  LOGOUT_SUCCESS,
  RESET_PASSWORD_FAILED,
  RESET_PASSWORD_START,
  RESET_PASSWORD_SUCCESS,
  SIGN_UP_FAILED,
  SIGN_UP_START,
  SIGN_UP_SUCCESS
} from "../actions/auth"

const initialState = {
  isLoading: false,
  user: null,
  isFailed: false
}

const authReducer = function (state = initialState, action) {
  switch (action.type) {
    case LOGIN_START:
      return {
        ...state,
        isLoading: true
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        isFailed: false
      }
    case LOGIN_FAILED:
      return {
        ...state,
        isLoading: false,
        user: null,
        isFailed: true
      }
    case LOGOUT_SUCCESS:
    case LOGOUT_FAILED:
      return {
        ...state,
        isLoading: false,
        user: null,
        isFailed: false
      }
    case SIGN_UP_START:
      return {
        ...state,
        isLoading: true
      }
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: action.payload,
        isFailed: false
      }
    case SIGN_UP_FAILED:
      return {
        ...state,
        isLoading: false,
        user: null,
        isFailed: true
      }
    case RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: true,
        user: null,
        isFailed: false
      }
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: null,
        isFailed: false
      }
    case RESET_PASSWORD_FAILED:
      return {
        ...state,
        isLoading: false,
        user: null,
        isFailed: true
      }
    default:
      return state
  }
}

export default authReducer
