import { CircularProgress, Container, Grid, Typography } from "@material-ui/core"
import NftCard from "../NftCard"
import useNftListStyles from "./styles"
import { useTranslation } from "react-i18next"
import BasePaper from "../BasePaper"

interface NftListProps {
  assets: Array<any>
  onCardPress: (asset: any) => void
  isLoadingSearch: boolean
}

export default function NftList({ assets, onCardPress, isLoadingSearch }: NftListProps) {
  const classes = useNftListStyles()
  const { t } = useTranslation()

  if (!assets) {
    return null
  }

  if (isLoadingSearch) {
    return <CircularProgress />
  }

  if (assets.length === 0) {
    return (
      <div className={classes.paperContainer}>
        <BasePaper className={classes.paper}>
          <Container>
            <Typography variant="h5">{t("screens.Home.noAssets")}</Typography>
          </Container>
        </BasePaper>
      </div>
    )
  }
  const assetsList =
    assets &&
    assets?.map((asset) => (
      <Grid key={asset.asset} item xs={3}>
        <NftCard asset={asset} onCardPress={onCardPress} />
      </Grid>
    ))
  return (
    <Grid container spacing={3}>
      {assetsList}
    </Grid>
  )
}
