import { makeStyles } from "@material-ui/core"

const useVideoPlayerThumbnailStyles: any = makeStyles(() => ({
  wrapper: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    position: "relative"
  },
  icon: {
    position: "absolute",
    margin: "auto",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
}))

export default useVideoPlayerThumbnailStyles
