import paths from "./paths"

/**
 * The options used to configure the API.
 */
export interface ApiConfig {
  production: boolean
  api: {
    url: string
    timeout: number
    paths: any
    skipAuthHeadersPaths: string[]
    headers: any
  }
}

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  api: {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    paths: {
      profile: paths.PROFILE,
      walletUsers: paths.WALLET_USERS,
      aspireApi: paths.ASPIRE_API,
      nodeGetBalances: paths.NODE_GET_BALANCES,
      salableAssets: paths.SALABLE_ASSETS,
      orderRequests: paths.ORDER_REQUESTS,
      nodeSignRaw: paths.NODE_SIGN_RAW_TRANSACTION,
      nodeUploadAndPinData: paths.NODE_UPLOAD_AND_PIN_DATA
    },
    skipAuthHeadersPaths: [],
    timeout: 10000,
    url: process.env.REACT_APP_API_BASE_URL
  },
  production: !!process.env.REACT_APP_IS_PROD
}
