import { combineReducers, Reducer } from "redux"

import assetsReducer from "./assets"
import authReducer from "./auth"
import dialogReducer from "./dialog"
import walletReducer from "./wallet"
import cartReducer from "./cart"

export type GetStateFromReducers<T> = T extends (...args: any[]) => infer Ret
  ? Ret
  : T extends Record<any, any>
  ? {
      [K in keyof T]: GetStateFromReducers<T[K]>
    }
  : T

const allReducers: Reducer<any> = combineReducers({
  assetsReducer,
  authReducer,
  walletReducer,
  dialogReducer,
  cartReducer
})

export type GlobalState = GetStateFromReducers<typeof allReducers>

export default allReducers
