import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export const useConfirmDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      justifyContent: "center"
    },
    submit: {
      marginRight: theme.spacing(1)
    }
  })
)

export default useConfirmDialogStyles
