import CssBaseline from "@material-ui/core/CssBaseline"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import AppBar from "../../components/AppBar"
import { CircularProgress, Divider, Modal, Typography } from "@material-ui/core"
import Footer from "../../components/Footer"
import {
  fetchBalancesStart,
  removeSalableAssetFromSale,
  sellAssetStart
} from "../../store/actions/wallet"
import { useDispatch, useSelector } from "react-redux"
import React, { useState } from "react"
import BalancesList from "../../components/BalancesList"
import { GlobalState } from "../../store/reducers"
import { BalanceModel } from "../../Models/balance"
import { useTranslation } from "react-i18next"
import useWalletStyles from "./styles"
import CopyButton from "../../components/CopyButton"
import { getFirstWalletAddress } from "../../utils/userHelper"
import CoinBalance from "../../components/CoinBalance"
import { ASPIRE_COIN_ASSETS } from "../../utils/constants"
import AssetCardPreview from "../../components/AssetCardPreview"
import { notify } from "react-notify-toast"
import BaseConfirmDialog from "../../components/BaseConfirmDialog"
import { SellAssetFormValues } from "../../components/SellAssetForm/SellAssetForm"

const Wallet = () => {
  const classes: any = useWalletStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [openSellModal, setOpenSellModal] = useState(false)
  const [openRemoveSaleDialog, setOpenRemoveSaleDialog] = useState(false)
  const [currentSalableAssetId, setCurrentSalableAssetId] = useState(null)
  const [assetPreview, setAssetPreview] = useState(null)
  const { user } = useSelector((state: GlobalState) => state.authReducer)
  const { balances, loadingBalances, gaspBalance, aspBalance, enerchiBalance, loadingSellAsset } =
    useSelector((state: GlobalState) => state.walletReducer)
  const fetchBalances = () => dispatch(fetchBalancesStart())
  const startSellAsset = (data) => dispatch(sellAssetStart(data))
  const removeSalableAsset = (salableAssetId, callback) =>
    dispatch(removeSalableAssetFromSale(salableAssetId, callback))
  const { walletAddresses } = user
  const walletAddress = getFirstWalletAddress(walletAddresses)

  React.useEffect(() => {
    fetchBalances()
  }, [])

  const onSellPress = (asset: BalanceModel) => {
    setAssetPreview(asset)
    handleOpen()
  }

  const onRemoveSalePress = (salableAssetId) => {
    setCurrentSalableAssetId(salableAssetId)
    setOpenRemoveSaleDialog(true)
  }

  const onRemoveSellSuccess = () => {
    setOpenRemoveSaleDialog(false)
    notify.show(t("screens.Wallet.removeOfSaleSuccess"), "success")
  }

  const onSubmitRemoveSale = () => {
    removeSalableAsset(currentSalableAssetId, onRemoveSellSuccess)
  }

  const onSubmitSell = (data: SellAssetFormValues) => {
    const { price, quantity } = data
    const params = {
      price,
      assetName: assetPreview.asset,
      details: assetPreview.description,
      name: assetPreview.assetName,
      quantity,
      callback: onAssetSellSuccess
    }
    startSellAsset(params)
  }

  const onAssetSellSuccess = () => {
    handleClose()
    notify.show(t("screens.Wallet.sellSuccess"), "success")
  }

  const handleOpen = () => {
    setOpenSellModal(true)
  }

  const handleClose = () => {
    setOpenSellModal(false)
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Box display="flex" justifyContent="space-between" flexDirection="row">
            <Box display="flex" flex={1} justifyContent="center">
              <Typography component="h1" variant="h3">
                {t("screens.Wallet.title")}
              </Typography>
            </Box>
            <CopyButton classes={classes} textToCopy={walletAddress} />
          </Box>
          <Grid container spacing={2} md={12} className={classes.addressGrid}>
            <CoinBalance
              classes={classes}
              type={ASPIRE_COIN_ASSETS.ENERCHI}
              balance={enerchiBalance}
            />
            <CoinBalance classes={classes} type={ASPIRE_COIN_ASSETS.GASP} balance={gaspBalance} />
            <CoinBalance classes={classes} type={ASPIRE_COIN_ASSETS.ASP} balance={aspBalance} />
          </Grid>
          <Box mt={4}>
            <Typography component="h1" variant="h3">
              {t("screens.Wallet.assetsTitle")}
            </Typography>
            <Divider />
          </Box>
          <Grid container spacing={5} className={classes.contentGrid}>
            {loadingBalances ? (
              <div className={classes.loadingContainer}>
                <CircularProgress />
              </div>
            ) : (
              <>
                <BalancesList
                  onSellPress={onSellPress}
                  onRemoveSalePress={onRemoveSalePress}
                  balances={balances}
                />
              </>
            )}
          </Grid>
        </Container>
        <Box pt={4}>
          <Footer />
        </Box>
      </main>
      <Modal
        open={openSellModal}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className={classes.modalPaper}>
          <AssetCardPreview
            classes={classes}
            isLoadingAssetSell={loadingSellAsset}
            asset={assetPreview}
            onSubmitSell={onSubmitSell}
          />
        </div>
      </Modal>
      <BaseConfirmDialog
        visible={openRemoveSaleDialog}
        title={t("screens.Wallet.confirmRemoveSaleDialog.title")}
        subtitle={t("screens.Wallet.confirmRemoveSaleDialog.subTitle")}
        okButtonText={t("screens.Wallet.confirmRemoveSaleDialog.okButton")}
        cancelButtonText={t("screens.Wallet.confirmRemoveSaleDialog.cancelButton")}
        okButtonPress={onSubmitRemoveSale}
        onClose={() => setOpenRemoveSaleDialog(false)}
      />
    </div>
  )
}

export default Wallet
