import { createStore, compose, applyMiddleware } from "redux"
import createSagaMiddleware from "redux-saga"
import allReducers from "./reducers"
import { rootSaga } from "./sagas"
// import { composeWithDevTools } from "redux-devtools-extension"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import { Environment, setupEnvironment } from "../api/environment"
import hardSet from "redux-persist/lib/stateReconciler/hardSet"
// export const store = createStore(allReducers, composeWithDevTools())

const composeEnhancer =
  (process.env.NODE_ENV !== "production" && window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]) ||
  compose

const persistConfig = {
  key: "root",
  storage,
  stateReconciler: hardSet
}

const persistedReducer = persistReducer(persistConfig, allReducers)

export default () => {
  const env: Environment = setupEnvironment()
  const sagaMiddleware = createSagaMiddleware({
    context: {
      env
    }
  })
  const store = createStore(persistedReducer, {}, composeEnhancer(applyMiddleware(sagaMiddleware)))
  sagaMiddleware.run(rootSaga)
  const persistor = persistStore(store)
  return { store, persistor }
}
