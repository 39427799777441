import { useBaseContainerClasses } from "./styles"
import { ReactElement } from "react"

interface BaseContainerProps {
  children: ReactElement | ReactElement[]
  customClass?: any
}

export default function BaseContainer({ children, customClass = "" }: BaseContainerProps) {
  const classes: any = useBaseContainerClasses()

  return <main className={`${classes.rootScene} ${customClass}`}>{children}</main>
}
