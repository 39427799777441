import React, { useState } from "react"
import CssBaseline from "@material-ui/core/CssBaseline"
import Box from "@material-ui/core/Box"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import { useDispatch, useSelector } from "react-redux"
import AppBar from "../../components/AppBar"
import Typography from "@material-ui/core/Typography"
import { Avatar, Button, CardContent, Link, Modal } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import Footer from "../../components/Footer"
import BuyStepper from "../../components/BuyStepper"
import { useHistory } from "react-router-dom"
import AccountBoxSharp from "@material-ui/icons/AccountBoxSharp"
import { GlobalState } from "../../store/reducers"
import useStyles from "./styles"
import IpfsView from "../../components/IpfsView/IpfsVIew"
import useAssetData from "../../hooks/useAssetData"
import { getFirstWalletAddress, getOwnerFullName, getOwnerIsBuyer } from "../../utils/userHelper"
import { fetchCoinValueStart, requestBuyAssetStart } from "../../store/actions/cart"
import { getIsVideoFromFileExtension } from "../../utils/constants"
import VideoPlayer from "../../components/VideoPlayer"
import BaseContainer from "components/BaseContainer"
import BaseCard from "../../components/BaseCard"

const AssetDetail = () => {
  const history: any = useHistory()
  const classes = useStyles()
  const { selectedAsset } = useSelector((state: GlobalState) => state.assetsReducer)
  const { loadingBuy, coinValue } = useSelector((state: GlobalState) => state.cartReducer)
  const { user } = useSelector((state: GlobalState) => state.authReducer)
  const walletAddress = getFirstWalletAddress(user?.walletAddresses)
  const dispatch = useDispatch()
  if (!selectedAsset) {
    history.push("/")
    return null
  }
  const assetExtraData = useAssetData({
    assetDescription: selectedAsset.details
  })
  const { assetName, assetImageUrl } = assetExtraData
  const [isVideo, setIsVideo] = useState(false)

  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const goToHome = () => {
    history.push("/login")
  }

  const ownerFullName = selectedAsset.user ? getOwnerFullName(selectedAsset.user) : ""
  // eslint-disable-next-line react/prop-types
  const ownerInitialLetter = ownerFullName.substr(0, 1).toUpperCase()

  const onSubmitBuy = (data, callback) => {
    dispatch(requestBuyAssetStart(data, callback))
  }

  const onFetchCoinValue = (coin, price) => {
    dispatch(fetchCoinValueStart(coin, price))
  }

  React.useEffect(() => {
    if (assetImageUrl) {
      setIsVideo(getIsVideoFromFileExtension(assetImageUrl))
    }
  }, [assetImageUrl])

  const canBuy = !getOwnerIsBuyer(selectedAsset.user, user)

  const body = (
    <div className={classes.modalPaper}>
      {user ? (
        <BuyStepper
          handleClose={handleClose}
          asset={selectedAsset}
          assetExtraData={assetExtraData}
          onSubmitBuy={onSubmitBuy}
          isLoadingRequestBuy={loadingBuy}
          walletAddress={walletAddress}
          onFetchCoinValue={onFetchCoinValue}
          coinValue={coinValue}
        />
      ) : (
        <div className={classes.authModal}>
          <AccountBoxSharp fontSize="large" />
          <Typography variant="h5" className={classes.authModalTitle}>
            {t("screens.AssetDetail.titleNotLoggedIn")}
          </Typography>
          <div className={classes.authModalAction}>
            <Button variant="contained" onClick={goToHome}>
              {t("screens.AssetDetail.signInButton")}
            </Button>
          </div>
        </div>
      )}
    </div>
  )

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar />
      <BaseContainer customClass={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container justifyContent="center" spacing={1}>
                <Grid item xs={6}>
                  <BaseCard className={classes.rootCard}>
                    {/*<div className={classes.cardHeader}>
                      <IconButton aria-label="favorites">
                        <FavoriteIcon />
                      </IconButton>
                      <Typography variant="body1" color="textSecondary" component="p">
                        {selectedAsset.likes || 0}
                      </Typography>
                    </div>*/}
                    {isVideo ? (
                      <VideoPlayer src={assetImageUrl} />
                    ) : (
                      <IpfsView assetImageUrl={assetImageUrl} imageStyle={classes.media} />
                    )}
                  </BaseCard>
                </Grid>
                <Grid item xs={6}>
                  <BaseCard className={classes.rootAssetDataCard}>
                    <CardContent>
                      <Typography variant="h4" color="textSecondary" noWrap>
                        {assetName}
                      </Typography>
                      <div className={classes.ownerContainer}>
                        <Avatar aria-label="recipe" className={classes.avatar}>
                          {ownerInitialLetter}
                        </Avatar>
                        <Typography variant="body1" color="textSecondary" component="p">
                          {t("screens.AssetDetail.ownedBy")} <Link>{ownerFullName}</Link>
                        </Typography>
                      </div>
                    </CardContent>
                  </BaseCard>
                  <BaseCard className={classes.rootCurrencyCard}>
                    <Typography variant="h6" align="left" color="textSecondary">
                      {t("screens.AssetDetail.currentPrice")}
                    </Typography>
                    <div className={classes.currencyContainer}>
                      <Typography variant="h5" color="textSecondary" component="p">
                        {parseInt("" + selectedAsset.price * 100) / 100}
                        <span className={classes.currencyBold}> USD</span>
                      </Typography>
                    </div>
                    {canBuy && (
                      <Button variant="contained" onClick={handleOpen} color="primary">
                        {t("screens.AssetDetail.buyNow")}
                      </Button>
                    )}
                  </BaseCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        <Box pt={4}>
          <Footer />
        </Box>
      </BaseContainer>
      <Modal
        open={open}
        onClose={handleClose}
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  )
}

export default AssetDetail
