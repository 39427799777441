import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

export const useBasePaperClasses = makeStyles((theme: Theme) =>
  createStyles({
    rootPaper: {
      backgroundColor: "rgba(0,0,0,.3)",
      color: "#ffffff"
    }
  })
)
