export const FETCH_ASSETS_START = "FETCH_ASSETS_START"
export const FETCH_ASSETS_SUCCESS = "FETCH_ASSETS_SUCCESS"
export const FETCH_ASSETS_FAILED = "FETCH_ASSETS_FAILED"
export const SELECT_ASSET = "SELECT_ASSET"
export const CLEAR_ASSET_SELECTED = "CLEAR_ASSET_SELECTED"
export const NEW_ASSET = "NEW_ASSET"
export const NEW_ASSET_SUCCESS = "NEW_ASSET_SUCCESS"
export const NEW_ASSET_FAILED = "NEW_ASSET_FAILED"
export const SEARCH_ASSETS_START = "SEARCH_ASSETS_START"
export const SEARCH_ASSETS_SUCCESS = "SEARCH_ASSETS_SUCCESS"
export const SEARCH_ASSETS_FAILED = "SEARCH_ASSETS_FAILED"
export const CLEAN_ASSETS = "CLEAN_ASSETS"
export const UPDATE_METADATA = "UPDATE_METADATA"

export const fetchAssetsStart = (page, search) => ({
  type: FETCH_ASSETS_START,
  payload: {
    page,
    search
  }
})

export const fetchAssetsSuccess = (assets) => ({
  type: FETCH_ASSETS_SUCCESS,
  payload: assets
})

export const fetchAssetsFailed = () => ({
  type: FETCH_ASSETS_FAILED
})

export const selectAsset = (asset) => ({
  type: SELECT_ASSET,
  payload: asset
})

export const clearAssetSelected = () => ({
  type: CLEAR_ASSET_SELECTED
})

export const newAsset = (data, passphrase, callback, callbackError) => ({
  type: NEW_ASSET,
  payload: {
    data,
    callback,
    passphrase,
    callbackError
  }
})

export const newAssetSuccess = () => ({
  type: NEW_ASSET_SUCCESS
})

export const newAssetFailed = () => ({
  type: NEW_ASSET_FAILED
})

export const searchAssetsStart = (search, page) => ({
  type: SEARCH_ASSETS_START,
  payload: {
    search,
    page
  }
})

export const searchAssetsSuccess = (assets) => ({
  type: SEARCH_ASSETS_SUCCESS,
  payload: assets
})

export const searchAssetsFailed = () => ({
  type: SEARCH_ASSETS_FAILED
})

export const cleanAssets = () => ({
  type: CLEAN_ASSETS
})

export const updateMetadata = (metadata) => ({
  type: UPDATE_METADATA,
  payload: metadata
})
